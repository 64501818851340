import { VariantProps, cva } from 'class-variance-authority';
import { ComponentPropsWithRef, FC, ForwardedRef, ReactNode, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';

/* =========================================================================================================
 ContentViewerFloating
============================================================================================================ */

const contentViewerFloatingVariants = cva(['absolute z-[9] flex flex-col gap-4'], {
    variants: {
        position: {
            'top-left': ['top-6 left-6 items-start'],
            'top-right': ['top-6 right-6 items-end'],
            'bottom-left': ['bottom-6 left-6 items-start'],
            'bottom-right': ['bottom-6 right-6 items-end'],
        },
    },
    defaultVariants: {
        position: 'top-left',
    },
});

interface ContentViewerFloatingProps extends VariantProps<typeof contentViewerFloatingVariants> {
    children: ReactNode;
    className?: string;
}

const ContentViewerFloating: FC<ContentViewerFloatingProps> = (props) => {
    const { children, className, position } = props;

    return <div className={twMerge(contentViewerFloatingVariants({ position, className }))}>{children}</div>;
};

/* =========================================================================================================
 ContentViewer
============================================================================================================ */
interface ContentViewerComposition {
    Floating?: typeof ContentViewerFloating;
}

interface ContentViewerProps extends ComponentPropsWithRef<'div'> {}

const ContentViewer = forwardRef<HTMLDivElement, ContentViewerProps>((props, ref) => {
    const { children, className, ...rest } = props;

    return (
        <div {...rest} ref={ref} className={twMerge('relative h-[calc(100vh-var(--header-height))]', className)}>
            {children}
        </div>
    );
}) as React.ForwardRefExoticComponent<ContentViewerProps> & ContentViewerComposition;

ContentViewer.Floating = ContentViewerFloating;

export default ContentViewer;
