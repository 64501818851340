import { CameraStatusEnum } from '@api/enum/camera-status.enum';
import { PermissionEnum } from '@api/enum/permission.enum';
import { ICameraCCTV } from '@api/models/camera-cctv.model';
import { Button, Card, Drawer, LinearProgress } from '@hyperflake/react-ui-library';
import { FormHelperText, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import http from 'httpConfig/http';
import upperCase from 'lodash.uppercase';
import useCctvCameraUpdateForm from 'modules/cctv-cameras/hooks/useCctcCameraUpdateForm';
import useCctvCameraData from 'modules/cctv-cameras/hooks/useCctvCameraData';
import FormikDatePicker from 'modules/shared/components/FormFields/FormikDatePicker';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { FormikInput, FormikSelect } from 'modules/shared/features/formik';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import moment from 'moment';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ChangeProjectForm from '../ChangeProjectForm/ChangeProjectForm';

interface ICctvCameraEditForm {
    // onClose: () => void;
    // cctvCamera: ICameraCCTV;
    // onUpdateCctvCamera: (cctvCamera: ICameraCCTV) => void;
}

const CctvCameraEditForm = (props: ICctvCameraEditForm) => {
    // const { onClose, cctvCamera, onUpdateCctvCamera } = props;
    const { clientId, projectId, cctvCameraId } = useParams();
    const { cctvCamera, isLoading } = useCctvCameraData(clientId, projectId, cctvCameraId);

    const CctvCameraUpdateForm = useCctvCameraUpdateForm({ clientId, projectId, cctvCamera });

    // const queryClient = useQueryClient();
    // console.log('cctv', cctvCamera);

    const cameraStatusOptions = Object.values(CameraStatusEnum).map((status) => ({
        label: upperCase(status),
        value: status,
    }));

    const { data: storages, isLoading: storageIsLoading } = useQuery<string[]>(
        ['clients', clientId, 'projects', projectId, 'cc-cameras', 'storage_names'],
        async () => {
            const { data } = await http.get(
                `${
                    import.meta.env.VITE_APP_API_URL
                }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/storage-names`
            );
            return data;
        }
    );

    const transformData = (values: any) => {
        return {
            ...values,
            installationDate: moment(values.installationDate).format('YYYYMMDD'),
        };
    };

    const handleSubmit = async (values: any) => {
        try {
            const tranformedData = transformData(values);
            const data = await CctvCameraUpdateForm.submit(tranformedData);
            toast.success('Live Camera Updated Successfully');
            // onUpdateCctvCamera(data);

            // onClose();
        } catch (e: any) {
            console.log(e);
            toast.error('Oops something went wrong.');
        }
    };

    const storageOption = useMemo(() => {
        if (!storages) return [];
        return [{ label: 'Select Storage', value: '' }, ...storages.map((val: string) => ({ label: val, value: val }))];
    }, [storages]);

    const { hasPermission } = useAuthPermission();

    return (
        <PageTemplate>
            <PageHeading>Edit Live Camera</PageHeading>
            {isLoading ? (
                <>
                    <LinearProgress />
                </>
            ) : (
                <div>
                    <Formik
                        initialValues={CctvCameraUpdateForm.initialValues}
                        enableReinitialize
                        validationSchema={CctvCameraUpdateForm.schema}
                        onSubmit={handleSubmit}
                    >
                        {() => (
                            <Form>
                                <fieldset disabled={!hasPermission(PermissionEnum.LIVE_CAMERA_UPDATE)}>
                                    <Card className="mt-4">
                                        <Card.Header>Basic Details</Card.Header>
                                        {/* name */}
                                        <Card.Body>
                                            <div>
                                                <div className="">
                                                    <Label>
                                                        Name <RequiredMark />
                                                    </Label>
                                                    <FormikInput type="text" name="name" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="name" />
                                                    </FormHelperText>
                                                </div>
                                                {/* installation Date */}
                                                <div className="">
                                                    <Label>
                                                        Installation Date <RequiredMark />
                                                    </Label>
                                                    <FormikDatePicker name="installationDate" maxDate={new Date()} />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="installationDate" />
                                                    </FormHelperText>
                                                </div>
                                                {/* Streaming Url */}
                                                <div>
                                                    <Label>
                                                        Streaming Url <RequiredMark />
                                                    </Label>
                                                    <div className="flex gap-2">
                                                        <FormikInput name="streamingUrl" />
                                                    </div>
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="streamingUrl" />
                                                    </FormHelperText>
                                                </div>
                                                {/* qLiveUnit */}
                                                <div>
                                                    <Label>
                                                        QLive Unit <RequiredMark />
                                                    </Label>
                                                    <div className="flex gap-2">
                                                        <FormikInput name="qLiveUnit" />
                                                    </div>
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="qLiveUnit" />
                                                    </FormHelperText>
                                                </div>
                                                {/* storage */}
                                                <div>
                                                    <Label>
                                                        Storage <RequiredMark />
                                                    </Label>
                                                    <div className="flex gap-2">
                                                        <FormikSelect name="storage" options={storageOption} />
                                                        {storageIsLoading && <CircularProgress size={14} />}
                                                    </div>
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="storage" />
                                                    </FormHelperText>
                                                </div>
                                                {/* latitude
                                        <div className="">
                                            <Label> Latitude </Label>
                                            <FormikInput type="text" name="location.latitude" />
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="location.latitude" />
                                            </FormHelperText>
                                        </div>
                                        {/* longitude */}
                                                {/* <div className="">
                                        <Label>Longitude</Label>
                                        <FormikInput type="text" name="location.longitude" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="location.longitude" />
                                        </FormHelperText>
                                    </div> */}

                                                {/* Status */}
                                                <div className="">
                                                    <Label>Status</Label>
                                                    <FormikSelect name="status" options={cameraStatusOptions} />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="status" />
                                                    </FormHelperText>
                                                </div>
                                            </div>
                                            <Button
                                                type="submit"
                                                size={'lg'}
                                                className="mt-8 w-28"
                                                disabled={CctvCameraUpdateForm.isSubmitting}
                                            >
                                                {CctvCameraUpdateForm.isSubmitting ? (
                                                    <CircularProgress size={20} color={'inherit'} />
                                                ) : (
                                                    'Submit'
                                                )}
                                            </Button>
                                        </Card.Body>
                                    </Card>
                                </fieldset>
                            </Form>
                        )}
                    </Formik>
                    <Card className="mt-4">
                        <Card.Header>Change Project</Card.Header>
                        <Card.Body>
                            <ChangeProjectForm cctvCamera={cctvCamera} />
                        </Card.Body>
                    </Card>
                </div>
            )}
        </PageTemplate>
    );
};

export default CctvCameraEditForm;
