interface UnlockIconProps {
    width: number;
    height: number;
    className?: string;
}

const UnlockIcon = ({ width, height, className }: UnlockIconProps) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={className}
    >
        <rect x="3" y="11" width="18" height="11" rx="2" ry="2" />
        <path d="M17 11V7a5 5 0 00-9.9-1" />
    </svg>
);

export default UnlockIcon;
