import { useMemo, useRef } from 'react';
// import { Breadcrumb } from '@library/breadcrumb';
import { ICamera } from '@api/models/camera.model';
import { useNavigate, useParams } from 'react-router-dom';
import AddCameraDrawer from '../AddCameraDrawer/AddCameraDrawer';
import CameraDeleteConfirmationDrawer from '../CameraDeleteConfirmationDrawer/CameraDeleteConfirmationDrawer';
// import useAuthPermission from '@hooks/use-auth-permission';
import { PermissionEnum } from '@api/enum/permission.enum';
import useDialog from '@hooks/use-dialog';
import { Button } from '@hyperflake/react-ui-library';
import { useCameraListData } from 'modules/cameras/hooks/useCameraListData';
import { EmptyDataView, PageHeading, PageTemplate } from 'modules/shared/features/content';
import { BlockLoader } from 'modules/shared/features/loaders';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import CameraCard from '../CameraCard/CameraCard';
import CameraDrawerLayout from '../CameraDrawerLayout/CameraDrawerLayout';

const CameraList = () => {
    const navigate = useNavigate();
    const { clientId, projectId } = useParams();
    const topRef = useRef<HTMLDivElement>();
    const addDrawerDialog = useDialog();
    const editDrawerDialog = useDialog<string>();
    const deleteDrawerDialog = useDialog<ICamera>();

    const { hasPermission } = useAuthPermission();

    const cameraListData = useCameraListData(clientId, projectId);

    const cameras = useMemo(() => {
        if (!cameraListData.cameraList) return [];

        return cameraListData.cameraList;
    }, [cameraListData.cameraList]);

    if (cameraListData.isLoading) return <BlockLoader />;

    return (
        <PageTemplate>
            <div className="flex justify-between">
                <PageHeading>TimeLapse Cameras</PageHeading>

                {hasPermission(PermissionEnum.TIMELAPSE_CAMERA_CREATE) && (
                    <Button onClick={() => navigate(`/clients/${clientId}/projects/${projectId}/cameras/add-camera`)}>
                        Add Camera
                    </Button>
                )}
            </div>

            <div className="grid grid-cols-4 gap-6 mt-6 empty:hidden">
                {cameras.map((camera) => (
                    <div
                        className="col-span-1"
                        key={camera._id}
                        onClick={() => navigate(`/clients/${clientId}/projects/${projectId}/cameras/${camera._id}`)}
                    >
                        <CameraCard
                            camera={camera}
                            onEditClick={() =>
                                navigate(`/clients/${clientId}/projects/${projectId}/cameras/${camera._id}/edit-camera`)
                            }
                            onBimClick={() =>
                                navigate(`/clients/${clientId}/projects/${projectId}/cameras/${camera._id}/bim-viewer`)
                            }
                            onDeleteClick={(camera) => deleteDrawerDialog.show(camera)}
                            // setEditDrawer={setEditDrawer}
                            hasPermission={hasPermission(PermissionEnum.TIMELAPSE_CAMERA_DELETE)}
                        />
                    </div>
                ))}
            </div>

            {cameras.length === 0 && <EmptyDataView message="No cameras found" />}

            <AddCameraDrawer
                open={addDrawerDialog.isOpen}
                onClose={addDrawerDialog.hide}
                onCameraAdd={cameraListData.addCamera}
            />

            <CameraDrawerLayout
                open={editDrawerDialog.isOpen}
                onClose={editDrawerDialog.hide}
                cameraId={editDrawerDialog.data}
                onCameraUpdate={(camera) => cameraListData.updateCamera(camera._id, camera)}
            />

            <CameraDeleteConfirmationDrawer
                open={deleteDrawerDialog.isOpen}
                onClose={deleteDrawerDialog.hide}
                camera={deleteDrawerDialog.data}
            />
        </PageTemplate>
    );
};

export default CameraList;
