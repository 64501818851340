import { ProjectStatusEnum } from '@api/enum/project-status.enum';
import { IProject } from '@api/models/project.model';
import { Drawer } from '@library/drawer';
import uppercase from 'lodash.uppercase';
import useNavName from 'modules/shared/hooks/useNavName';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router';

// import useAuthPermission from '@hooks/use-auth-permission';
import { Tooltip } from '@library/tooltip';
import ProjectAddDrawerForm from '../ProjectAddDrawerForm/ProjectAddDrawerForm';
import ProjectDeleteConfirmationDrawer from '../ProjectDeleteConfirmationDrawer/ProjectDeleteConfirmationDrawer';
import ProjectEditDrawerForm from '../ProjectEditDrawerForm/ProjectEditDrawerForm';
import UserAccessDrawerFrom from '../UserAccessDrawerFrom/UserAccessDrawerFrom';

import { PermissionEnum } from '@api/enum/permission.enum';
import useDialog from '@hooks/use-dialog';
import { Button, Chip, Table } from '@hyperflake/react-ui-library';
import { acitivityColor } from 'modules/projects/helpers/project.utils';
import useProjectListData from 'modules/projects/hooks/useProjectListData';
import EventBlocker from 'modules/shared/components/EventBlocker/EventBlocker';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { SearchBar } from 'modules/shared/features/search';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { DeleteShapeIcon, EditIcon } from 'modules/shared/icons';

const ProjectList = () => {
    const navigate = useNavigate();
    const { clientId } = useParams();
    // const { clientName } = useNavName(clientId);
    const projectListData = useProjectListData(clientId);
    const { hasPermission } = useAuthPermission();

    // const navItems: IBreadcrumbItem[] = [
    //     { label: 'Clients', onClick: () => navigate('/client') },
    //     { label: clientName, onClick: () => navigate(`/client?query=${clientId}`) },
    //     { label: 'Projects' },
    // ];
    const addDrawerDialog = useDialog();
    const editDrawerDialog = useDialog<string>();
    const renderDrawerDialog = useDialog<string>();
    const confirmationsDrawerDialog = useDialog<IProject>();

    // const [addDrawer, setAddDrawer] = useState({ open: false, anchor: 'right' });
    // const [editDrawer, setEditDrawer] = useState({
    //     open: false,
    //     anchor: 'right',
    //     id: '',
    // });
    // const [rendersDrawer, setRendersDrawer] = useState({
    //     open: false,
    //     anchor: 'right',
    //     id: '',
    // });
    // const [confirmationDrawer, setConfirmationDrawer] = useState<{
    //     open: boolean;
    //     anchor: string;
    //     project: null | IProject;
    // }>({ open: false, anchor: 'right', project: null });
    const [useAccessDrawer, setUseAccessDrawer] = useState<{
        open: boolean;
        anchor: string;
        project: null | IProject;
    }>({ open: false, anchor: 'right', project: null });

    // const projects = useMemo(() => {
    //     if (!projectListData.data) return [];
    //     return projectListData.data;
    // }, [projectListData.data]);

    // const projectHierarchy = useProjectHierarchySelection({
    //     projects: projects,
    // });

    // const sortProjectHierarchical = () => {
    //     let arrangedArray: any = [];

    //     const handleChildren = (id: string) => {
    //         if (!projectHierarchy.hasChildren(id)) return;
    //         projectHierarchy.getChildren(id).forEach((proj) => {
    //             arrangedArray.push(proj);
    //             handleChildren(proj._id);
    //         });
    //     };

    //     const grandParentList = projectHierarchy.getChildren('root');

    //     if (!grandParentList) return arrangedArray;
    //     grandParentList.forEach((grandProject) => {
    //         arrangedArray.push(grandProject);
    //         handleChildren(grandProject._id);
    //     });
    //     return arrangedArray;
    // };

    // console.log('after search', projectListData.filteredProjects as IProject[]);

    return (
        <PageTemplate>
            {/* <Navbar /> */}
            {/* <div className="pt-[var(--navbar-height)]"> */}

            {/* <Breadcrumb items={navItems} />
                <div className="mt-6">
                    <h3>Projects</h3>
                    <Dropdown placement="bottom-start">
                        <DropdownTrigger className=" rounded-full flex items-baseline">
                            <div className="flex items-baseline gap-2">
                                <h3>Projects</h3>
                                <ChevronDownIcon width={18} height={12} />
                            </div>
                        </DropdownTrigger>

                        <DropdownList className="min-w-[100px]">
                            <DropdownItem onClick={() => navigate(`/client/${clientId}/user`)}>
                                {`View ${clientName}'s users`}
                            </DropdownItem>
                        </DropdownList>
                    </Dropdown>
                </div> */}
            <div className="flex justify-between">
                <PageHeading>Projects</PageHeading>
                {hasPermission(PermissionEnum.PROJECT_CREATE) && (
                    // <Button onClick={() => addDrawerDialog.show()}>Add New Project</Button>
                    <Button onClick={() => navigate(`/clients/${clientId}/projects/add-project`)}>
                        Add New Project
                    </Button>
                )}
            </div>
            <div className="mt-6">
                <SearchBar value={projectListData.searchValue} onChange={projectListData.setSearchQuery} />
            </div>
            <div>
                <Table
                    borderStyle="divider"
                    className="mt-6"
                    hover
                    isLoading={projectListData.isLoading}
                    // data={sortProjectHierarchical() as IProject[]}
                    data={projectListData.filteredProjects as IProject[]}
                    columns={[
                        {
                            header: 'ID',
                        },
                        {
                            header: 'Name',
                        },
                        {
                            header: 'Location',
                        },
                        {
                            header: 'Parent',
                        },
                        {
                            header: 'Activity',
                        },
                        {
                            header: 'Status',
                        },
                        {
                            header: 'Action',
                        },
                    ]}
                    renderRow={(row) => (
                        <Table.Row
                            key={row._id}
                            onClick={() => {
                                // row.hasCameras
                                //     ? navigate(`/clients/${clientId}/projects/${row._id}/cameras`)
                                //     : navigate(`/clients/${clientId}/projects/${row._id}`);
                                navigate(`/clients/${clientId}/projects/${row._id}/project-details`);
                            }}
                        >
                            <Table.Cell>{row._id}</Table.Cell>
                            <Table.Cell>
                                <div>{row.name}</div>
                            </Table.Cell>
                            <Table.Cell>
                                <div>{row.location.name}</div>
                            </Table.Cell>
                            <Table.Cell>{row.parentId ? row.parentId : '-'}</Table.Cell>
                            <Table.Cell>
                                {row?.activity ? (
                                    <Chip
                                        variant="shaded"
                                        color={acitivityColor(row?.activity)}
                                        label={uppercase(row?.activity)}
                                        size={'sm'}
                                    />
                                ) : (
                                    <div className="">-</div>
                                )}
                            </Table.Cell>
                            <Table.Cell>
                                {
                                    <Chip
                                        variant="shaded"
                                        color={row.status === ProjectStatusEnum.ACTIVE ? 'success' : 'warning'}
                                        label={row.status}
                                        size={'sm'}
                                    />
                                }
                            </Table.Cell>
                            <Table.Cell>
                                <EventBlocker stopPropagation preventDefault>
                                    <>
                                        {/* <Dropdown placement="bottom-start" showArrow>
                                            <DropdownTrigger className="w-4 h-4 rounded-full p-4">
                                                <EllipsisVerticalIcon />
                                            </DropdownTrigger>

                                            <DropdownList className="min-w-[100px]">
                                                <DropdownItem onClick={() => editDrawerDialog.show(row._id)}>
                                                    Edit
                                                </DropdownItem>

                                                {hasAdminPermission ? (
                                                    <DropdownItem
                                                        onClick={() => confirmationsDrawerDialog.show(row)}
                                                        className="text-danger"
                                                    >
                                                        Delete
                                                    </DropdownItem>
                                                ) : (
                                                    <Tooltip
                                                        placement="left"
                                                        content={'Only Admins are authorized to delete project'}
                                                    >
                                                        <DropdownItem
                                                            className="text-gray-300 cursor-not-allowed"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            Delete
                                                        </DropdownItem>
                                                    </Tooltip>
                                                )}
                                            </DropdownList>
                                        </Dropdown> */}
                                        <EventBlocker stopPropagation preventDefault>
                                            <div className="grid gap-2 grid-cols-2">
                                                {/* <button
                                                    onClick={() =>
                                                        // editDrawerDialog.show(row._id)
                                                        navigate(
                                                            `/clients/${clientId}/projects/${row._id}/edit-project`
                                                        )
                                                    }
                                                >
                                                    <EditIcon />
                                                </button> */}
                                                {hasPermission(PermissionEnum.PROJECT_DELETE) ? (
                                                    <button onClick={() => confirmationsDrawerDialog.show(row)}>
                                                        <DeleteShapeIcon />
                                                    </button>
                                                ) : (
                                                    <Tooltip
                                                        placement="left"
                                                        content={'Only Admins are authorized to delete project'}
                                                    >
                                                        <button className="text-gray-300 cursor-not-allowed">
                                                            <DeleteShapeIcon />
                                                        </button>
                                                    </Tooltip>
                                                )}
                                            </div>
                                        </EventBlocker>
                                    </>
                                </EventBlocker>
                            </Table.Cell>
                        </Table.Row>
                    )}
                />
            </div>
            {/* Add Project drawer */}
            <ProjectAddDrawerForm
                open={addDrawerDialog.isOpen}
                onClose={addDrawerDialog.hide}
                addProject={projectListData.onAddProject}
                projects={projectListData.data}
            />

            {/* edit drawer */}
            <ProjectEditDrawerForm
                open={editDrawerDialog.isOpen}
                projectId={editDrawerDialog.data}
                onClose={editDrawerDialog.hide}
                updateProject={(project) => projectListData.onUpdateProject(project._id, project)}
            />

            {/* Renders drawer */}
            {/* <ProjectRendersDrawer
                open={renderDrawerDialog.isOpen}
                onClose={renderDrawerDialog.hide}
                projectId={renderDrawerDialog.data}
            /> */}

            {/* delete confirmation Dialog */}
            <ProjectDeleteConfirmationDrawer
                open={confirmationsDrawerDialog.isOpen}
                onClose={confirmationsDrawerDialog.hide}
                project={confirmationsDrawerDialog.data}
            />

            {/* User Access Dialog */}
            <Drawer
                open={useAccessDrawer.open}
                onClose={() => setUseAccessDrawer({ open: false, anchor: 'right', project: null })}
                anchor={useAccessDrawer.anchor as any}
            >
                <UserAccessDrawerFrom
                    onClose={() => setUseAccessDrawer({ open: false, anchor: 'right', project: null })}
                    project={useAccessDrawer.project}
                />
            </Drawer>
        </PageTemplate>
    );
};

export default ProjectList;
