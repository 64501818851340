import moment from 'moment';
import { useMemo } from 'react';
import { IClient } from '@api/models';
import { useQuery } from '@tanstack/react-query';
import { ChevronDownIcon, EllipsisVerticalIcon } from '@icon/index';
import { useSearchParams } from 'react-router-dom';
import { getAllUsers } from '@api/services/user.service';
import RoleChip from 'modules/shared/components/RoleChip/RoleChip';
import StatusChip from 'modules/shared/components/StatusChip/StatusChip';
import TableDataLoading from 'modules/shared/components/TableDataLoading/TableDataLoading';
import EmptyTableTemplate from 'modules/shared/components/EmptyTableTemplate/EmptyTableTemplate';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { Table } from '@hyperflake/react-ui-library';
import SelectDropdown from '@components/SelectDropDown/SelectDropDown';
import { useAllUserList } from 'modules/users/hooks/useAllUserList';

const AllUsers = () => {
    // const { data, isLoading } = useQuery(['All users'], () => getAllUsers());

    const { allUsers, isLoading, filterOptions, changeFilterValue, filterValue } = useAllUserList();

    // const users = useMemo(() => {
    //     if (!allUser) return [];
    //     return allUser;
    // }, [allUser]);

    const [searchParams, setSsearchParams] = useSearchParams();

    const navigate = useNavigate();

    return (
        <PageTemplate>
            <div className="flex justify-between">
                <PageHeading>All Users</PageHeading>
                <div>
                    <SelectDropdown
                        className="btn-dropdown btn-secondary btn-secondary-hover "
                        value={filterValue}
                        onChange={changeFilterValue}
                        options={filterOptions}
                        render={(label) => (
                            <span className="flex items-center gap-2 text-grayscale-500 font-medium">
                                View:<span className="text-grayscale-900 font-semibold">{label}</span>{' '}
                                <span>
                                    <ChevronDownIcon className="text-grayscale-400" strokeWidth="2" />
                                </span>
                            </span>
                        )}
                    />
                </div>
            </div>

            <Table
                className="mt-6"
                borderStyle="divider"
                // hover
                isLoading={isLoading}
                data={allUsers}
                columns={[
                    {
                        header: 'ID',
                    },
                    {
                        header: 'Name',
                    },
                    {
                        header: 'Email',
                    },
                    {
                        header: 'Last Active',
                    },
                    {
                        header: 'Role',
                    },
                    {
                        header: 'Status',
                    },
                    {
                        header: 'Action',
                    },
                ]}
                renderRow={(row) => (
                    <Table.Row key={row._id} onClick={() => {}}>
                        <Table.Cell>
                            {
                                <div
                                    className="text-primary cursor-pointer"
                                    // onClick={() => setEditDrawer({ open: true, anchor: 'right', id: row._id })}

                                    onClick={() => {
                                        navigate({
                                            pathname: `/clients/${(row.client as IClient)._id}/users`,
                                            search: createSearchParams({
                                                showDialog: 'true',
                                                userid: row._id,
                                            }).toString(),
                                        });
                                    }}
                                >
                                    {row._id}
                                </div>
                            }
                        </Table.Cell>
                        <Table.Cell>{row.name}</Table.Cell>
                        <Table.Cell>{row.email}</Table.Cell>
                        <Table.Cell>
                            {row.lastActive ? (
                                <div className="whitespace-nowrap">
                                    <div className="">
                                        {moment(row.lastActive, 'YYYYMMDDHHmmss').format('DD MMM YYYY')}
                                    </div>
                                    <div className="">{moment(row.lastActive, 'YYYYMMDDHHmmss').format('HH:mm A')}</div>
                                </div>
                            ) : (
                                '-'
                            )}
                        </Table.Cell>
                        <Table.Cell>{<RoleChip status={row.role} />}</Table.Cell>
                        <Table.Cell>{<StatusChip status={row.status} />}</Table.Cell>
                        <Table.Cell>
                            {
                                <>
                                    <Dropdown placement="bottom-start" showArrow>
                                        <DropdownTrigger className="w-4 h-4 rounded-full">
                                            <EllipsisVerticalIcon />
                                        </DropdownTrigger>

                                        <DropdownList className="min-w-[100px]">
                                            <DropdownItem
                                                onClick={() => {
                                                    navigate({
                                                        pathname: `/clients/${(row.client as IClient)._id}/users`,
                                                        search: createSearchParams({
                                                            showDialog: 'true',
                                                            userid: row._id,
                                                        }).toString(),
                                                    });
                                                }}
                                            >
                                                Edit
                                            </DropdownItem>
                                            {/* <DropdownItem
                                                    onClick={() =>
                                                        setAccessDrawer({ open: true, anchor: 'right', id: row._id })
                                                    }
                                                >
                                                    Project Access
                                                </DropdownItem> */}
                                            {/* <DropdownItem className="cursor-default text-slate-200">
                                                    Delete
                                                </DropdownItem> */}
                                        </DropdownList>
                                    </Dropdown>
                                </>
                            }
                        </Table.Cell>
                    </Table.Row>
                )}
            />
            {!isLoading && allUsers.length === 0 && <EmptyTableTemplate />}
            {/* {isLoading && <TableDataLoading />} */}

            {/* </div> */}
            {/* Add drawer */}
            {/* <Drawer
                open={addDrawer.open}
                onClose={() => setAddDrawer({ open: false, anchor: 'right' })}
                anchor={addDrawer.anchor as any}
            >
                <UserAddDrawerForm
                    onClose={(id: string) => {
                        setAccessDrawer({ open: true, anchor: 'right', id });
                        setAddDrawer({ open: false, anchor: 'right' });
                    }}
                />
            </Drawer> */}
            {/* edit drawer */}
            {/* <Drawer
                open={editDrawer.open}
                onClose={() => setEditDrawer({ open: false, anchor: 'right', id: '' })}
                anchor={editDrawer.anchor as any}
            >
                <UserEditDrawerForm
                    userId={editDrawer.id}
                    onClose={() => setEditDrawer({ open: false, anchor: 'right', id: '' })}
                />
            </Drawer> */}
            {/* Project Access Drawer */}
            {/* <Drawer
                open={accessDrawer.open}
                onClose={() => setAccessDrawer({ open: false, anchor: 'right', id: '' })}
                anchor={accessDrawer.anchor as any}
            >
                <ProjectAccessDrawerForm
                    onClose={() => setAccessDrawer({ open: false, anchor: 'right', id: '' })}
                    userId={accessDrawer.id}
                />
            </Drawer> */}
        </PageTemplate>
    );
};

export default AllUsers;
