const CloudIllustration = (props: any) => {
    return (
        <svg width={152} height={120} viewBox="0 0 152 120" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <circle cx={76} cy={52} r={52} fill="#EAECF0" />
            <g filter="url(#filter0_dd_795_20479)">
                <path
                    d="M77.6 16c-10.773 0-20.302 5.323-26.101 13.483A25.672 25.672 0 0045.6 28.8C31.461 28.8 20 40.261 20 54.4 20 68.539 31.462 80 45.6 80h64c12.371 0 22.4-10.029 22.4-22.4 0-12.371-10.029-22.4-22.4-22.4-.879 0-1.746.05-2.598.149C102.098 23.968 90.78 16 77.6 16z"
                    fill="#F9FAFB"
                />
                <ellipse cx={45.6} cy={54.3998} rx={25.6} ry={25.6} fill="url(#paint0_linear_795_20479)" />
                <circle cx={77.6} cy={48} r={32} fill="url(#paint1_linear_795_20479)" />
                <ellipse cx={109.6} cy={57.6002} rx={22.4} ry={22.4} fill="url(#paint2_linear_795_20479)" />
            </g>
            <circle cx={21} cy={19} r={5} fill="#F2F4F7" />
            <circle cx={18} cy={109} r={7} fill="#F2F4F7" />
            <circle cx={145} cy={35} r={7} fill="#F2F4F7" />
            <circle cx={134} cy={8} r={4} fill="#F2F4F7" />
            <g filter="url(#filter1_b_795_20479)">
                <rect x={52} y={62} width={48} height={48} rx={24} fill="#344054" fillOpacity={0.4} />
                <path
                    d="M85 95l-3.5-3.5m2.5-6a8.5 8.5 0 11-17 0 8.5 8.5 0 0117 0z"
                    stroke="#fff"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_dd_795_20479"
                    x={0}
                    y={16}
                    width={152}
                    height={104}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology radius={4} in="SourceAlpha" result="effect1_dropShadow_795_20479" />
                    <feOffset dy={8} />
                    <feGaussianBlur stdDeviation={4} />
                    <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_795_20479" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology radius={4} in="SourceAlpha" result="effect2_dropShadow_795_20479" />
                    <feOffset dy={20} />
                    <feGaussianBlur stdDeviation={12} />
                    <feColorMatrix values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0" />
                    <feBlend in2="effect1_dropShadow_795_20479" result="effect2_dropShadow_795_20479" />
                    <feBlend in="SourceGraphic" in2="effect2_dropShadow_795_20479" result="shape" />
                </filter>
                <filter
                    id="filter1_b_795_20479"
                    x={44}
                    y={54}
                    width={64}
                    height={64}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation={4} />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_795_20479" />
                    <feBlend in="SourceGraphic" in2="effect1_backgroundBlur_795_20479" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_795_20479"
                    x1={25.9429}
                    y1={37.4855}
                    x2={71.2}
                    y2={79.9998}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D0D5DD" />
                    <stop offset={0.350715} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_795_20479"
                    x1={53.0286}
                    y1={26.8571}
                    x2={109.6}
                    y2={80}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D0D5DD" />
                    <stop offset={0.350715} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_795_20479"
                    x1={92.4}
                    y1={42.8002}
                    x2={132}
                    y2={80.0002}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D0D5DD" />
                    <stop offset={0.350715} stopColor="#fff" stopOpacity={0} />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default CloudIllustration;
