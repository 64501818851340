import jwtDecode from 'jwt-decode';

import { createListenerMiddleware, createSlice } from '@reduxjs/toolkit';
import { QueryClient } from '@tanstack/react-query';

const getResellerIdFromtoken = (token: string) => {
    const decoded = jwtDecode(token);
    // return decoded?.resellerId;
    return (decoded as { groupId: string }).groupId;
};

type IAuthData = {
    token: string | null;
    resellerId: string | null;
};

const initialState: IAuthData = {
    token: null,
    resellerId: null,
};

const TOKEN_KEY = 'a_p_token';
// const USER_KEY = 'fa_user';

const getStorage = () => {
    if (localStorage.getItem(TOKEN_KEY) != null) {
        return localStorage;
    }

    if (sessionStorage.getItem(TOKEN_KEY) != null) {
        return sessionStorage;
    }

    return null;
};

const getInitialState = (): IAuthData => {
    const storage = getStorage();

    if (!storage) return initialState;

    return {
        token: storage.getItem(TOKEN_KEY),
        resellerId: '',
        // resellerId: getResellerIdFromtoken(storage.getItem(TOKEN_KEY)),
    };
};

const authSlice = createSlice({
    name: 'auth',
    initialState: getInitialState(),
    reducers: {
        login(state, action) {
            // const { token, user } = action.payload;
            const { token } = action.payload;

            state.token = token;
            // state.resellerId = getResellerIdFromtoken(token);
        },
        setToken(state, action) {
            // state.token = action.payload;
            // state.resellerId = getResellerIdFromtoken(action.payload);

            state.resellerId = action.payload;
        },
        // setUser(state, action) {
        //     state.user = action.payload;
        // },
        removeToken(state) {
            // removeToken(state, action) {
            state.token = null;
            state.resellerId = null;
        },
        // removeUser(state, action) {
        //     state.user = null;
        // },
        logout() {
            return initialState;
        },
    },
});

export const authActions = authSlice.actions;

export const authListenerMiddleware = createListenerMiddleware();
// Listen for login
authListenerMiddleware.startListening({
    actionCreator: authActions.login,
    effect: (action) => {
        // effect: (action, listenerApi) => {
        const payload = action.payload;

        if (payload.rememberMe) {
            localStorage.setItem(TOKEN_KEY, payload.token);
            // localStorage.setItem(USER_KEY, JSON.stringify(payload.user));
        } else {
            sessionStorage.setItem(TOKEN_KEY, payload.token);
            // sessionStorage.setItem(USER_KEY, JSON.stringify(payload.user));
        }
    },
});
// Listen for setUser
// authListenerMiddleware.startListening({
//     actionCreator: authActions.setUser,
//     effect: (action, listenerApi) => {
//         const user = action.payload;

//         const storage = getStorage();
//         storage.setItem(USER_KEY, JSON.stringify(user));
//     },
// });
// Listen for logout
authListenerMiddleware.startListening({
    actionCreator: authActions.logout,
    effect: () => {
        // effect: (action, listenerApi) => {
        const storage = getStorage();

        if (storage) {
            storage.removeItem(TOKEN_KEY);
        }

        const queryClient = new QueryClient();
        queryClient.clear();

        // storage.removeItem(USER_KEY);
    },
});

export default authSlice;
