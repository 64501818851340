import { UserStatusEnum } from '@api/enum';
import { getAllUsers } from '@api/services/user.service';
import useDataQuery from '@hooks/use-data-query';
import React, { useCallback, useMemo, useState } from 'react';
import { FilterValues } from '../helpers/user.utils';

export const useAllUserList = () => {
    const usersListQuery = useDataQuery(['All users'], () => getAllUsers());
    const [filterValue, setFilterValue] = useState('ALL');

    const changeFilterValue = useCallback(
        (val: string | number) => {
            // setIsSortable(false);
            setFilterValue(val as string);
        },
        [setFilterValue]
    );

    const allUsers = useMemo(() => {
        if (usersListQuery.isLoading) return [];

        const _users = usersListQuery.data;

        const filterProjects =
            filterValue === FilterValues.ALL ? _users : _users.filter((user) => user.status === filterValue);

        return filterProjects;
    }, [usersListQuery.isLoading, usersListQuery.data, filterValue]);

    const filterOptions = useMemo(() => {
        if (usersListQuery.isLoading) return [];

        const users = usersListQuery.data;

        const _filterOptions = [
            {
                label: `All (${users.length})`,
                value: FilterValues.ALL,
            },
            {
                label: `Enabled (${users.filter((row) => row.status === UserStatusEnum.ENABLED).length})`,
                value: FilterValues.ENABLED,
            },
            {
                label: `Disabled (${users.filter((row) => row.status === UserStatusEnum.DISABLED).length})`,
                value: FilterValues.DISABLED,
            },
        ];

        return _filterOptions;
    }, [usersListQuery.isLoading, usersListQuery.data]);

    return {
        allUsers,
        isLoading: usersListQuery.isLoading,
        changeFilterValue,
        filterOptions,
        filterValue,
    };
};
