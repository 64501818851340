import { Card, CardProps } from '@hyperflake/react-ui-library';
import EventBlocker from 'modules/shared/components/EventBlocker/EventBlocker';
import { ReactNode } from 'react';
import clsx from 'clsx';

interface WithClassName {
    className?: string;
}

interface GalleryCardMediaProps extends WithClassName {
    children: ReactNode;
}

const GalleryCardMedia = ({ children, className }: GalleryCardMediaProps) => (
    <div className={clsx('mb-5', className)}>
        <div className="w-full aspect-image rounded-lg object-cover object-center">{children}</div>
    </div>
);

interface GalleryCardActionBarProps extends WithClassName {
    children: ReactNode;
}

const GalleryCardActionBar = ({ children, className }: GalleryCardActionBarProps) => (
    <EventBlocker
        className={clsx('absolute top-6 right-6 z-[1] flex items-center gap-2', className)}
        stopPropagation
        preventDefault
    >
        {children}
    </EventBlocker>
);

interface GalleryCardTitleProps extends WithClassName {
    children: ReactNode;
}

const GalleryCardTitle = ({ children, className }: GalleryCardTitleProps) => (
    <div className={clsx('font-semibold', className)}>{children}</div>
);

interface GalleryCardFooterProps extends WithClassName {
    children: ReactNode;
}

const GalleryCardFooter = ({ children, className }: GalleryCardFooterProps) => (
    <div className={clsx('mt-auto', className)}>
        <div className="mt-4">{children}</div>
    </div>
);

interface GalleryCardFooterItemProps extends WithClassName {
    label: ReactNode;
    content: ReactNode;
}

const GalleryCardFooterItem = ({ label, content, className }: GalleryCardFooterItemProps) => (
    <div className={className}>
        <div className="text-[11px] text-grayscale-500">{label}</div>
        <div className="text-xs text-grayscale-900 font-medium mt-2">{content}</div>
    </div>
);

interface GalleryCardProps extends CardProps<'div'>, WithClassName {
    children: ReactNode;
}

const GalleryCard = ({ children, className, ...rest }: GalleryCardProps) => (
    <Card
        {...rest}
        className={clsx(
            'relative rounded-xl cursor-pointer transition-shadow duration-300 hover:shadow-[0px_4px_16px_rgba(4,34,99,0.08)] h-full',
            className
        )}
    >
        <Card.Body className="relative p-4">{children}</Card.Body>
    </Card>
);

GalleryCard.Media = GalleryCardMedia;
GalleryCard.ActionBar = GalleryCardActionBar;
GalleryCard.Title = GalleryCardTitle;
GalleryCard.Footer = GalleryCardFooter;
GalleryCard.FooterItem = GalleryCardFooterItem;

export default GalleryCard;
