import { useEffect, useMemo, useState } from 'react';

interface UseLoadingTextProps {
    messages: string[];
    delay?: number;
}

const useLoadingText = (props: UseLoadingTextProps) => {
    const { messages, delay = 3000 } = props;

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex((prev) => {
                const next = prev + 1;

                if (next === messages.length) {
                    clearInterval(intervalId);
                }

                return Math.min(next, messages.length - 1);
            });
        }, delay);

        return () => {
            clearInterval(intervalId);
        };
    }, [delay, messages.length]);

    const text = useMemo(() => {
        return messages.length > 0 ? messages[index] : '';
    }, [index, messages]);

    return {
        text,
    };
};

export default useLoadingText;
