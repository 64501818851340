import clsx from 'clsx';

import debounce from 'lodash.debounce';
import { FC, useEffect, useMemo, useState } from 'react';
import ScrollLock from 'react-scrolllock';
import SearchResultRow from '../SearchResultRow/SearchResultRow';
import useSearchQuery from '../../hooks/useSearchQuery';
import { useFloatingElement } from '@library/floating';
import { SearchIcon } from '@icon/index';
import CloudIllustration from '@components/Illustrations/CloudIllustration';
import { CircularProgress } from '@hyperflake/react-ui-library';

interface SearchBarProps {}

const SearchBar: FC<SearchBarProps> = () => {
    const searchQuery = useSearchQuery();

    const { context, refs, getReferenceProps, getFloatingProps } = useFloatingElement<HTMLInputElement>({
        placement: 'bottom-start',
        offset: 8,
        triggers: [],
    });

    const [showSearchBarInMobileView, setShowSearchBarInMobileView] = useState(false);

    useEffect(() => {
        // Reset values whenever the search closes
        if (!context.open) {
            refs.domReference.current.value = ''; // Reset value
            searchQuery.resetResults();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.open]);

    // useEffect(() => {
    //     // Reset values and close floating search whenever screen size changes
    //     refs.domReference.current.value = ''; // Reset value
    //     searchQuery.resetResults();
    //     context.onOpenChange(false);
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [isDesktop]);

    // const showSearchbar = useMemo(() => showSearchBarInMobileView, [isDesktop, showSearchBarInMobileView]);

    const handleKeyUp = debounce((e: any) => {
        const val = e.target.value;

        if (val.length > 1) {
            context.onOpenChange(true);

            searchQuery.fetch(val);
        } else {
            context.onOpenChange(false);
        }
    }, 500);

    const handleFocus = (e: any) => {
        const val = e.target.value;

        if (val.length > 1) {
            context.onOpenChange(true);
        } else {
            context.onOpenChange(false);
        }
    };

    const handleOpenSearchBarInMobileView = () => {
        setShowSearchBarInMobileView(true);

        setTimeout(() => {
            refs.domReference.current.focus();
        }, 0);
    };

    const handleResultClick = () => {
        context.onOpenChange(false);

        setShowSearchBarInMobileView(false);
    };

    return (
        <>
            {/* <MobileOrTabletMediaQuery>
                <button type="button" onClick={handleOpenSearchBarInMobileView}>
                    <SearchIcon strokeWidth={1} width={22} height={22} />
                </button>
            </MobileOrTabletMediaQuery> */}

            {/* <div
                className={clsx(isDesktop ? 'relative' : 'fixed top-6 left-6 right-6 z-[18]', {
                    hidden: !showSearchbar,
                })}
            > */}
            <div className="relative">
                <div className="relative flex items-center overflow-hidden bg-card-color rounded-lg">
                    <input
                        {...getReferenceProps()}
                        ref={refs.setReference}
                        className="peer/search absolute left-0 pl-10 bg-transparent text-sm outline-none font-medium placeholder:text-grayscale-400 placeholder:font-normal w-full h-full"
                        placeholder="Search for anything"
                        onKeyUp={handleKeyUp}
                        onFocus={handleFocus}
                    />

                    <div className="pointer-events-none absolute left-4 text-grayscale-400 peer-focus/search:text-grayscale-900">
                        <SearchIcon strokeWidth={2} />
                    </div>

                    <div className="relative pointer-events-none border border-grayscale-300 theme-custom:border-grayscale-400 rounded-lg w-full md:w-[360px] lg:w-[420px] h-[40px] peer-focus/search:border-primary peer-focus/search:shadow-[0px_2px_4px_rgba(51,128,242,0.1),0px_0px_2px_rgba(51,128,242,0.12)]"></div>
                </div>

                <ScrollLock isActive={showSearchBarInMobileView} />

                <div>
                    {context.open && (
                        <div
                            {...getFloatingProps()}
                            ref={refs.setFloating}
                            style={context.floatingStyles}
                            className="floating-list w-full max-h-[498px] z-[15]"
                        >
                            {searchQuery.isEmpty && !searchQuery.isLoading ? (
                                <div className="flex flex-col items-center text-center py-6">
                                    <CloudIllustration width={132} height={100} />
                                    <div className="font-semibold mt-4">No results found</div>
                                    <div className="text-sm text-grayscale-500 mt-1">
                                        "{refs.domReference.current.value}" did not match any results. <br />
                                        Please try again.
                                    </div>
                                </div>
                            ) : (
                                searchQuery.groups.map(
                                    (group) =>
                                        (group.isLoading || group.data.length > 0) && (
                                            <div key={group.type}>
                                                <div className="text-grayscale-500 tracking-[0.8px] text-[0.65rem] uppercase px-4 py-1">
                                                    {group.label}
                                                </div>

                                                {group.isLoading ? (
                                                    <div className="flex items-center gap-3 py-2 px-4 text-sm text-grayscale-500">
                                                        <CircularProgress size={16} />
                                                        Loading...
                                                    </div>
                                                ) : (
                                                    group.data.map((row) => (
                                                        <SearchResultRow
                                                            key={row._id}
                                                            data={row}
                                                            type={group.type}
                                                            onResultClick={handleResultClick}
                                                        />
                                                    ))
                                                )}
                                            </div>
                                        )
                                )
                            )}
                        </div>
                    )}
                </div>
                {/* </div> */}

                {/* <MobileOrTabletMediaQuery>
                <Backdrop
                    show={showSearchBarInMobileView}
                    onClick={() => setShowSearchBarInMobileView(false)}
                    className="bg-white z-[17]"
                />
            </MobileOrTabletMediaQuery> */}
            </div>
        </>
    );
};

export default SearchBar;
