import { UnitTypeEnum } from '@api/enum';
import { ICamera } from '@api/models';
import { updateCamera } from '@api/services/camera.service';
import { useMutation } from '@tanstack/react-query';
import { resolutionOption } from 'modules/shared/helpers/shared.constants';
import moment from 'moment';
import * as Yup from 'yup';

interface EditCameraFormOptions {
    camera: ICamera;
    clientId: string;
    projectId: string;
    cameraId: string;
}
const useCameraUpdateForm = (options: EditCameraFormOptions) => {
    const { camera, clientId, projectId, cameraId } = options;

    const updateCameraMutation = useMutation((payload: ICamera) =>
        updateCamera(clientId, projectId, cameraId, payload)
    );

    const schema = Yup.object().shape({
        name: Yup.string().trim().required('This field is required'),
        installationDate: Yup.string().trim().required('This field is required'),
        details: Yup.object().shape({
            type: Yup.string().oneOf(Object.values(UnitTypeEnum)),
            version: Yup.string(),
        }),
        timezone: Yup.string().trim(),
        orientation: Yup.number()

            .typeError('You must specify a number')
            .min(0, 'Value must be greater or equal to zero')
            .max(360, 'Value must be less or equal to 360'),
        storage: Yup.string().required('This field is required'),
    });
    const initialValues = {
        _id: camera?._id,
        name: camera?.name,
        installationDate: camera?.installationDate
            ? moment(camera.installationDate, 'YYYYMMDD').toDate()
            : moment().toDate(),
        details: {
            type: camera?.details?.type,
            version: camera?.details?.version,
        },
        timezone: camera?.timezone,
        orientation: camera?.orientation,
        storage: camera?.storageName,
        status: camera?.status,
        folder: camera?.folder,
        latestImage: camera?.latestImage
            ? {
                  name: camera?.latestImage?.name,
                  resolution: JSON.stringify(camera?.latestImage?.resolution),
              }
            : { name: '', resolution: JSON.stringify(resolutionOption[(resolutionOption.length - 1) / 2]) },
        location: {
            latitude: camera?.location?.latitude,
            longitude: camera?.location?.longitude,
        },
        isPointedTo: camera?.isPointedTo,
    };

    const submit = async (values: ICamera) => {
        const data = await updateCameraMutation.mutateAsync(values);
        return data;
    };
    return {
        schema,
        initialValues,
        submit,
        isSubmitting: updateCameraMutation.isLoading,
    };
};
export default useCameraUpdateForm;
