import ColorPicker from '@components/ColorPicker/ColorPicker';
import RequiredMark from '@components/FormFields/RequiredMark';
import { Button, Card, CircularProgress, HelpText, Label } from '@hyperflake/react-ui-library';
import { UploadIcon } from '@icon/index';
import { ErrorMessage, Form, Formik } from 'formik';
import useClientAddForm from 'modules/clients/hooks/useClientAddForm';
import useClientStorages from 'modules/clients/hooks/useClientStorages';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { FormikInput, FormikSelect, FormikSwitch } from 'modules/shared/features/formik';
import { useMemo } from 'react';
import { ColorResult } from 'react-color';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const ClientAddForm = () => {
    const addClientForm = useClientAddForm();
    const navigate = useNavigate();

    const handleSubmit = async (values: any) => {
        try {
            const client = await addClientForm.submit(values);

            toast.success('Client added successfully');
            navigate(`/clients?search=${client.name}`);
        } catch (err: any) {
            console.log(err);

            toast.error('Oops something went wrong.');
        }
    };

    const { clientStorage: storages, isLoading: storageIsLoading } = useClientStorages();

    const storageOption = useMemo(() => {
        if (!storages) return [];
        return [{ label: 'Select Storage', value: '' }, ...storages.map((val: string) => ({ label: val, value: val }))];
    }, [storages]);
    return (
        <>
            <PageTemplate>
                <PageHeading>Add Client</PageHeading>

                <Formik
                    initialValues={addClientForm.initialValues}
                    enableReinitialize
                    validationSchema={addClientForm.schema}
                    onSubmit={handleSubmit}
                >
                    {({ values, setFieldValue }) => (
                        <Form>
                            {/* Logo and Email header */}
                            <Card className="mt-4">
                                <Card.Header>Profile Pictures</Card.Header>
                                <Card.Body>
                                    <div className="grid grid-cols-2">
                                        {/* logo */}
                                        <div className="flex flex-col items-start">
                                            <Label>Client Logo</Label>

                                            <div className="flex items-center gap-4 mt-2">
                                                <div className="relative w-20 h-20 bg-grayscale-200 rounded-full">
                                                    {values.logo && (
                                                        <>
                                                            <img
                                                                className="w-full h-full rounded-full "
                                                                src={URL.createObjectURL(values.logo)}
                                                                alt=""
                                                            />
                                                            <div
                                                                className="absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center text-xs font-semibold text-white bg-black/40 cursor-pointer select-none transition-opacity duration-300 opacity-0 hover:opacity-100"
                                                                onClick={() => setFieldValue('logo', null)}
                                                            >
                                                                Remove
                                                            </div>
                                                        </>
                                                    )}
                                                </div>

                                                <div className="relative">
                                                    <Button variant="shaded" size="xs">
                                                        <UploadIcon className="cursor-pointer" /> Upload Image
                                                        <input
                                                            type="file"
                                                            className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0"
                                                            onChange={(event) => {
                                                                const files = event.target.files;

                                                                if (files && files.length > 0) {
                                                                    setFieldValue('logo', files[0]);
                                                                }
                                                            }}
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>

                                        {/* email header */}
                                        <div className="flex flex-col items-start">
                                            <Label>Email Header</Label>

                                            <div className="flex items-center gap-4 mt-2">
                                                <div className="relative w-20 h-20 bg-grayscale-200 rounded-full">
                                                    {values.emailHeaderLogo && (
                                                        <>
                                                            <img
                                                                className="w-full h-full rounded-full "
                                                                src={URL.createObjectURL(values.emailHeaderLogo)}
                                                                alt=""
                                                            />
                                                            <div
                                                                className="absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center text-xs font-semibold text-white bg-black/40 cursor-pointer select-none transition-opacity duration-300 opacity-0 hover:opacity-100"
                                                                onClick={() => setFieldValue('emailHeaderLogo', null)}
                                                            >
                                                                Remove
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                <div className="relative">
                                                    <Button variant="shaded" size="xs">
                                                        <UploadIcon /> Upload Image
                                                        <input
                                                            type="file"
                                                            className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0"
                                                            onChange={(event) => {
                                                                const files = event.target.files;
                                                                if (files && files.length > 0) {
                                                                    setFieldValue('emailHeaderLogo', files[0]);
                                                                }
                                                            }}
                                                        />
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            {/* Other Details */}

                            <Card className="mt-4">
                                <Card.Header>Basic Details</Card.Header>
                                {/* name */}
                                <Card.Body>
                                    <div>
                                        <Label>
                                            Name <RequiredMark />
                                        </Label>

                                        <FormikInput type="text" name="name" />

                                        <HelpText variant="error">
                                            <ErrorMessage component="div" name="name" />
                                        </HelpText>
                                    </div>

                                    {/* domain */}
                                    <div className="mt-4">
                                        <Label>Domain</Label>

                                        <FormikInput type="text" name="domain" />

                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="domain" />
                                        </HelpText>
                                    </div>
                                    {/* storage */}
                                    <div className="mt-4">
                                        <Label>
                                            Storage <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect name="storage" options={storageOption} />

                                            {storageIsLoading && <CircularProgress size={14} />}
                                        </div>

                                        <HelpText variant={'error'}>
                                            <ErrorMessage name="storage" />
                                        </HelpText>
                                    </div>
                                </Card.Body>
                            </Card>

                            {/* preferences */}
                            <Card className="mt-8">
                                <Card.Header>Preferences</Card.Header>
                                {/* maxImageViewableDays */}
                                <Card.Body>
                                    <div className="grid grid-cols-2 gap-4 mt-4">
                                        <div>
                                            <Label>Max ImageViewable Days</Label>
                                            <FormikInput type="number" name="preset.maxImageViewableDays" />
                                        </div>
                                        {/* primary color */}
                                        <div>
                                            <Label>Primary Color</Label>

                                            <ColorPicker
                                                value={values.preferences.primaryColor}
                                                setColor={(event: ColorResult) => {
                                                    setFieldValue('preferences.primaryColor', event.hex.toUpperCase());
                                                }}
                                            />
                                        </div>
                                        {/* cdn enabled */}
                                        <div className="flex items-center gap-2 mt-4">
                                            <Label
                                                htmlFor="preferences.hasCdnEnabled"
                                                className="font-medium text-grayscale-900 mb-0"
                                            >
                                                Enable CDN Delivery
                                            </Label>

                                            <FormikSwitch color="success" name="preferences.hasCdnEnabled" />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            {/* mapCenter */}
                            <Card className="mt-8">
                                <Card.Header>Map</Card.Header>
                                <Card.Body>
                                    <div className="grid grid-cols-2 gap-4 mt-4">
                                        <div>
                                            <Label>Latitude</Label>
                                            <FormikInput type="number" name="preferences.mapCenter.latitude" />
                                        </div>
                                        <div>
                                            <Label>Longitude</Label>
                                            <FormikInput type="number" name="preferences.mapCenter.longitude" />
                                        </div>
                                        {/* mapZoom */}
                                        <div>
                                            <Label>Map Zoom</Label>
                                            <FormikInput type="number" name="preferences.mapZoom" />
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Button type="submit" className="mt-10" disabled={addClientForm.isSubmitting}>
                                {addClientForm.isSubmitting ? (
                                    <CircularProgress size={20} color={'inherit'} />
                                ) : (
                                    'Add Client'
                                )}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </PageTemplate>
        </>
    );
};

export default ClientAddForm;
