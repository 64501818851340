import { getAllResellerCamera } from '@api/services/reseller.camera.service';
import useDataQuery from '@hooks/use-data-query';
import { useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

const useResellerCameraListData = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    let searchQueryString = searchParams.get('search') || '';
    const [searchValue, setSearchValue] = useState(searchQueryString);

    const setSearchQuery = (value: string) => {
        setSearchValue(value);

        if (value) {
            searchParams.set('search', value);
        } else {
            searchParams.delete('search');
        }

        setSearchParams(searchParams);
    };

    const resllerCameraListQuery = useDataQuery(['reseller', 'all-camera'], () => getAllResellerCamera());

    const filteredCamera = useMemo(() => {
        let _Cameras = resllerCameraListQuery?.data?.rows;
        const val = searchValue.toLowerCase();

        if (val) {
            _Cameras = _Cameras?.filter((row) => {
                let flag = row.name.toLowerCase().includes(val);

                flag = flag || row._id.toLowerCase().includes(val);
                console.log('flag', flag);

                return flag;
            });
        }

        return _Cameras;
    }, [resllerCameraListQuery.isLoading, resllerCameraListQuery.data, searchValue]);

    return {
        data: resllerCameraListQuery.data,
        isLoading: resllerCameraListQuery.isLoading,
        searchValue,
        setSearchQuery,
        filteredCamera,
    };
};

export default useResellerCameraListData;
