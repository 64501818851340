import { getCameraBimDetails } from '@api/services/camera.service';
import { useQuery } from '@tanstack/react-query';
// import { getCameraBimDetails } from 'api/services';

const useCameraBimData = (clientId: string, projectId: string, cameraId: string) => {
    const bimQuery = useQuery(['cameraBim', projectId, cameraId], () =>
        getCameraBimDetails(clientId, projectId, cameraId)
    );

    return {
        isLoading: bimQuery.isLoading,
        details: bimQuery.data,
        error: bimQuery.error,
    };
};

export default useCameraBimData;
