import useResizeObserver from '@react-hook/resize-observer';
import { select } from 'd3-selection';
import { ZoomBehavior, zoom, zoomIdentity } from 'd3-zoom';
// import { useControlledState } from 'library/common';
import throttle from 'lodash.throttle';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { IZoomableState, UseZoomableProps, ZoomFnParams } from '../helpers/camera-bim-viewer.types';
import useControlledState from '@library/common/hooks/use-controlled-state';

const useBimZoom = (options: UseZoomableProps = {}) => {
    const {
        state: controlledState,
        setState: setControlledState,
        minZoom = 1,
        maxZoom = 14,
        isZoomed = false,
        onZoom,
    } = options;

    const [state, setState] = useControlledState<IZoomableState>({
        initialState: { x: 0, y: 0, k: 1 },
        value: controlledState,
        setValue: setControlledState,
    });

    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const zoomFnRef = useRef<ZoomBehavior<Element, unknown>>(null);

    const containerRefSetter = useCallback((node: HTMLElement) => {
        if (!node) return;

        containerRef.current = node;
        containerRef.current.style.overflow = 'hidden';
    }, []);

    const contentRefSetter = useCallback((node: HTMLElement) => {
        if (!node) return;

        contentRef.current = node;
        contentRef.current.style.transformOrigin = '0 0';
    }, []);

    useEffect(() => {
        if (!contentRef.current) return;

        contentRef.current.style.transform = `translate(${state.x}px, ${state.y}px) scale(${state.k})`;
    }, [state.k, state.x, state.y]);

    const containerDimensions = useMemo(() => {
        return {
            width: () => select(containerRef.current).node().offsetWidth,
            height: () => select(containerRef.current).node().offsetHeight,
        };
    }, []);

    const contentDimensions = useMemo(() => {
        return {
            width: () => select(contentRef.current).node().offsetWidth,
            height: () => select(contentRef.current).node().offsetHeight,
        };
    }, []);

    const centerContent = useCallback(() => {
        if (!zoomFnRef.current) return;

        const xOffset = contentDimensions.width() / 2 - containerDimensions.width() / 2;
        const yOffset = contentDimensions.height() / 2 - containerDimensions.height() / 2;

        select(containerRef.current).call(zoomFnRef.current.translateTo, xOffset, yOffset);
    }, [containerDimensions, contentDimensions]);

    const resetZoom = useCallback(() => {
        if (!zoomFnRef.current) return;

        select(containerRef.current).call(zoomFnRef.current.scaleTo, 1);
    }, []);

    const getScalingFactor = useCallback(() => {
        if (!zoomFnRef.current) return 1;

        return (
            Math.max(
                containerDimensions.width() / contentDimensions.height(),
                containerDimensions.height() / contentDimensions.height()
            ) || 1
        );
    }, [containerDimensions, contentDimensions]);

    useEffect(() => {
        if (!zoomFnRef.current) {
            zoomFnRef.current = zoom().on(
                'zoom',
                throttle((e) => {
                    const { transform } = e;

                    if (transform.toString() === zoomIdentity.toString()) {
                        return;
                    }

                    if (e.sourceEvent) {
                        onZoom?.(transform);
                    }

                    setState(transform);
                }, 10)
            );

            select(containerRef.current).call(zoomFnRef.current);
        }

        zoomFnRef.current.scaleExtent([minZoom, maxZoom]);

        zoomFnRef.current.constrain((transform, extent) => {
            const translateExtent = [
                [0, 0],
                [contentDimensions.width(), contentDimensions.height()],
            ];

            const dx0 = transform.invertX(extent[0][0]) - translateExtent[0][0],
                dx1 = transform.invertX(extent[1][0]) - translateExtent[1][0],
                dy0 = transform.invertY(extent[0][1]) - translateExtent[0][1],
                dy1 = transform.invertY(extent[1][1]) - translateExtent[1][1];

            return transform.translate(
                dx1 > dx0 ? (dx0 + dx1) / 2 : Math.min(0, dx0) || Math.max(0, dx1),
                dy1 > dy0 ? (dy0 + dy1) / 2 : Math.min(0, dy0) || Math.max(0, dy1)
            );
        });

        centerContent();

        resetZoom();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxZoom, minZoom, centerContent, resetZoom, setState, contentDimensions]);

    useResizeObserver(containerRef.current, () => {
        centerContent();

        resetZoom();

        if (isZoomed) {
            zoomToContent();
        }
    });

    const zoomIn = useCallback(() => {
        if (!zoomFnRef.current) return;

        const container = select(containerRef.current);

        container
            .transition()
            .duration(100)
            .call(zoomFnRef.current.scaleBy, 1 + 0.1);
    }, []);

    const zoomOut = useCallback(() => {
        if (!zoomFnRef.current) return;

        const container = select(containerRef.current);

        container
            .transition()
            .duration(100)
            .call(zoomFnRef.current.scaleBy, 1 - 0.1);
    }, []);

    const zoomTo = useCallback((k: number, params: ZoomFnParams = {}) => {
        const { withTransition = false } = params;

        if (!zoomFnRef.current) return;

        const container = withTransition
            ? select(containerRef.current).transition().duration(250)
            : select(containerRef.current);

        container.call(zoomFnRef.current.scaleTo, k);
    }, []);

    const zoomToContent = useCallback(
        (params: ZoomFnParams = {}) => {
            const { withTransition = false } = params;

            if (!zoomFnRef.current) return;

            const container = withTransition
                ? select(containerRef.current).transition().duration(250)
                : select(containerRef.current);

            const scalingFactor = getScalingFactor();

            container.call(zoomFnRef.current.scaleTo, scalingFactor);
        },
        [getScalingFactor]
    );

    const setTransform = useCallback((state: IZoomableState, params: ZoomFnParams = {}) => {
        const { withTransition = false } = params;

        if (!zoomFnRef.current) return;

        const container = withTransition
            ? select(containerRef.current).transition().duration(250)
            : select(containerRef.current);

        const transform = zoomIdentity.translate(state.x, state.y).scale(state.k);
        container.call(zoomFnRef.current.transform, transform);
    }, []);

    const refs = useMemo(() => {
        return {
            container: {
                setReference: containerRefSetter,
                reference: containerRef,
            },
            content: {
                setReference: contentRefSetter,
                reference: contentRef,
            },
        };
    }, [containerRefSetter, contentRefSetter]);

    return {
        refs,
        state,
        zoomIn,
        zoomOut,
        zoomTo,
        zoomToContent,
        setTransform,
    };
};

export default useBimZoom;
