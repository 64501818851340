import { IClient } from '@api/models';
import useDialog from '@hooks/use-dialog';
import { Avatar, Button, Card, CircularProgress, Label, LinearProgress } from '@hyperflake/react-ui-library';
import CardBody from '@hyperflake/react-ui-library/cards/components/CardBody.js';
import CardHeader from '@hyperflake/react-ui-library/cards/components/CardHeader.js';
import useClientData from 'modules/clients/hooks/useClientData';
import { PageTemplate } from 'modules/shared/features/content';
import React, { ReactNode, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ClientDeleteConfirmationDrawer from '../ClientDeleteConfirmationDrawer/ClientDeleteConfirmationDrawer';
import { Form, Formik } from 'formik';
import useClientUpdateBasicForm from 'modules/clients/hooks/useClientUpdateBasicForm';
import { toast } from 'react-toastify';
import useAuthPermission from '@hooks/use-auth-permission';
import { PermissionEnum } from '@api/enum/permission.enum';
import { FormikInput, FormikSelect, FormikSwitch } from 'modules/shared/features/formik';
import ColorPicker from '@components/ColorPicker/ColorPicker';
import { ClientStatusEnum } from '@api/enum';
import upperCase from 'lodash.uppercase';
import { ColorResult } from 'react-color';
import LogoForm from '../LogoForm/LogoForm';
import EmailHeaderForm from '../EmailHeaderForm/EmailHeaderForm';
import ClientLoginImagesForm from '../ClientLoginImagesForm/ClientLoginImagesForm';
import ClientEditFormPlugin from '../ClientEditFormPlugin/ClientEditFormPlugin';
import ClientEditFormBasic from '../ClientEditFormBasic/ClientEditFormBasic';

const ClientDetails = () => {
    // const { unit } = props;
    const { clientId } = useParams();
    const { client, isLoading, setData } = useClientData(clientId);
    const confirmationsDrawerDialog = useDialog<IClient>();
    const updateClientForm = useClientUpdateBasicForm({ client });

    const data: { label: string; value: ReactNode }[] = [
        {
            label: 'Client Id',
            value: client?._id,
        },
        {
            label: 'Name',
            value: (
                <div className="flex items-center gap-2">
                    <Avatar src={client?.logoUrl} alt={client?.name} bgColor={client?.preferences?.primaryColor} />
                    {client?.name}
                </div>
            ),
        },

        {
            label: 'Storage',
            value: client?.storageName,
        },
        {
            label: 'Status',
            value: client?.status,
        },
    ];

    const handleSubmit = async (values: any) => {
        try {
            const updatedClient = await updateClientForm.submit(values);

            handleClientUpdate(updatedClient);

            toast.success('Client updated successfully.');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };
    const { hasPermission } = useAuthPermission();
    const clientStatusOptions = useMemo(() => {
        return Object.values(ClientStatusEnum).map((status) => ({
            value: status,
            label: upperCase(status),
        }));
    }, []);
    const handleClientUpdate = (updatedClient: IClient) => {
        // onClientUpdate(updatedClient);
        setData(updatedClient);
    };
    const handleLogoUrlUpdate = (logoUrl: string) => {
        handleClientUpdate({ ...client, logoUrl });
    };

    const handleEmailHeaderLogoUrlUpdate = (emailHeaderLogoUrl: string) => {
        handleClientUpdate({ ...client, emailHeaderLogoUrl });
    };

    return (
        <PageTemplate>
            {/* {isLoading ? (
                <div className="flex flex-col justify-center items-center gap-2  h-36">
                    <CircularProgress />
                    <div className="text-secondary font-medium text-base">Fetching Data... </div>
                </div>
            ) : (
                <>
                    <Card className="">
                        <CardHeader>Client Details</CardHeader>
                        <CardBody>
                            <div className="grid grid-cols-12 gap-6">
                                {data.map((row, index) => (
                                    <div key={index} className="col-span-6 items-start gap-x-8">
                                        <div className="text-sm text-grayscale-500">{row.label} </div>
                                        <h6 className="mt-[6px]">{row.value}</h6>
                                    </div>
                                ))}
                            </div>
                        </CardBody>
                    </Card>
                    <Card className="mt-4">
                        <CardHeader>
                            <h6>Client Delete</h6>
                        </CardHeader>
                        <CardBody>
                            <Button onClick={() => confirmationsDrawerDialog.show(client)}>Delete</Button>
                        </CardBody>
                    </Card>
                </>
            )} */}
            {isLoading ? (
                <LinearProgress />
            ) : (
                // <div className="flex flex-col justify-center items-center gap-2  h-36">
                //     <CircularProgress />
                //     <div className="text-secondary font-medium text-base">Fetching Data... </div>
                // </div>
                <div>
                    <Card className="">
                        <Card.Header>Profile Pictures</Card.Header>
                        <Card.Body>
                            <div className="flex items-center gap-x-12 ">
                                <LogoForm client={client} onLogoUrlUpdate={handleLogoUrlUpdate} />
                                <EmailHeaderForm
                                    client={client}
                                    onEmailHeaderLogoUrlUpdate={handleEmailHeaderLogoUrlUpdate}
                                />
                            </div>
                        </Card.Body>
                    </Card>

                    {/* TODO modify ClientEditFormBasic */}

                    {/* <ClientEditFormBasic client={client} onClientUpdate={handleClientUpdate} /> */}

                    <Formik
                        initialValues={updateClientForm.initialValues}
                        enableReinitialize
                        validationSchema={updateClientForm.schema}
                        onSubmit={handleSubmit}
                    >
                        {({ values, setFieldValue }) => (
                            <Form>
                                <fieldset disabled={!hasPermission(PermissionEnum.CLIENT_UPDATE)}>
                                    <Card className="mt-4">
                                        <Card.Header>Basic Details</Card.Header>
                                        <Card.Body>
                                            <div className="grid grid-cols-2 gap-4">
                                                {/* name */}
                                                <div className="">
                                                    <Label>Name</Label>
                                                    <FormikInput type="text" name="name" />
                                                    {/* <FormHelperText variant={'error'}>
                                        <ErrorMessage name="name" />
                                    </FormHelperText> */}
                                                </div>
                                                {/* domain */}
                                                <div className="">
                                                    <Label>Domain</Label>
                                                    <FormikInput type="text" name="domain" />
                                                    {/* <FormHelperText variant={'error'}>
                                        <ErrorMessage name="domain" />
                                    </FormHelperText> */}
                                                </div>

                                                <div className="">
                                                    <Label>Status</Label>
                                                    <FormikSelect name="status" options={clientStatusOptions} />
                                                    {/* <FormHelperText variant={'error'}>
                                        <ErrorMessage name="status" />
                                    </FormHelperText> */}
                                                </div>
                                                {/* Storage */}
                                                <div className="">
                                                    <Label>Storage</Label>
                                                    <FormikInput type="text" name="storageName" disabled={true} />
                                                    {/* <FormHelperText variant={'error'}>
                                        <ErrorMessage name="storageName" />
                                    </FormHelperText> */}
                                                </div>
                                            </div>
                                            <Button
                                                type="submit"
                                                size={'lg'}
                                                className="mt-4"
                                                disabled={updateClientForm.isSubmitting}
                                            >
                                                {updateClientForm.isSubmitting ? (
                                                    <CircularProgress color={'inherit'} size={18} />
                                                ) : (
                                                    'Save'
                                                )}
                                            </Button>
                                        </Card.Body>
                                    </Card>

                                    {/* preferences */}
                                    <Card className="mt-4">
                                        <Card.Header>Preferences</Card.Header>
                                        <Card.Body>
                                            <div className="grid grid-cols-2 gap-4">
                                                {/* maxImageViewableDays */}

                                                <div>
                                                    <Label>Max ImageViewable Days</Label>
                                                    <FormikInput type="number" name="preset.maxImageViewableDays" />
                                                </div>
                                                {/* primary color */}
                                                <div>
                                                    <Label>Primary Color</Label>
                                                    <ColorPicker
                                                        value={values.preferences.primaryColor}
                                                        setColor={(event: ColorResult) => {
                                                            setFieldValue(
                                                                'preferences.primaryColor',
                                                                event.hex.toUpperCase()
                                                            );
                                                        }}
                                                    />
                                                </div>
                                                {/* cdn enabled */}
                                                <div className="">
                                                    <Label
                                                        htmlFor="preferences.hasCdnEnabled"
                                                        className="font-medium text-grayscale-900 mr-2"
                                                    >
                                                        Enable CDN Delivery
                                                    </Label>

                                                    <FormikSwitch color="success" name="preferences.hasCdnEnabled" />
                                                </div>
                                            </div>
                                            <Button
                                                type="submit"
                                                size={'lg'}
                                                className="mt-4"
                                                disabled={updateClientForm.isSubmitting}
                                            >
                                                {updateClientForm.isSubmitting ? (
                                                    <CircularProgress color={'inherit'} size={18} />
                                                ) : (
                                                    'Save'
                                                )}
                                            </Button>
                                        </Card.Body>
                                    </Card>

                                    <Card className="mt-4">
                                        <Card.Header>Maps</Card.Header>
                                        <Card.Body>
                                            {/* mapCenter */}

                                            <div className="grid grid-cols-2 gap-4 ">
                                                <div>
                                                    <Label>Latitude</Label>
                                                    <FormikInput type="number" name="preferences.mapCenter.latitude" />
                                                </div>
                                                <div>
                                                    <Label>Longitude</Label>
                                                    <FormikInput type="number" name="preferences.mapCenter.longitude" />
                                                </div>
                                                {/* mapZoom */}
                                                <div>
                                                    <Label>Map Zoom</Label>
                                                    <FormikInput type="number" name="preferences.mapZoom" />
                                                </div>
                                            </div>
                                            <Button
                                                type="submit"
                                                size={'lg'}
                                                className="mt-4"
                                                disabled={updateClientForm.isSubmitting}
                                            >
                                                {updateClientForm.isSubmitting ? (
                                                    <CircularProgress color={'inherit'} size={18} />
                                                ) : (
                                                    'Save'
                                                )}
                                            </Button>
                                        </Card.Body>
                                    </Card>

                                    {/* <Button
                                        type="submit"
                                        size={'lg'}
                                        className="mt-4"
                                        disabled={updateClientForm.isSubmitting}
                                    >
                                        {updateClientForm.isSubmitting ? (
                                            <CircularProgress color={'inherit'} size={18} />
                                        ) : (
                                            'Save'
                                        )}
                                    </Button> */}
                                </fieldset>
                            </Form>
                        )}
                    </Formik>

                    <Card className="mt-4">
                        <Card.Header>Login Images</Card.Header>
                        <Card.Body>
                            <ClientLoginImagesForm client={client} onClientUpdate={handleClientUpdate} />
                        </Card.Body>
                    </Card>
                    <Card className="mt-4">
                        <Card.Header>Plugins</Card.Header>
                        <Card.Body>
                            <ClientEditFormPlugin onClientUpdate={handleClientUpdate} client={client} />{' '}
                        </Card.Body>
                    </Card>

                    <Card className="mt-4">
                        <Card.Header>Client Delete</Card.Header>
                        <Card.Body>
                            <Button onClick={() => confirmationsDrawerDialog.show(client)}>Delete</Button>
                        </Card.Body>
                    </Card>

                    <ClientDeleteConfirmationDrawer
                        open={confirmationsDrawerDialog.isOpen}
                        onClose={confirmationsDrawerDialog.hide}
                        client={confirmationsDrawerDialog.data}
                    />
                </div>
            )}
        </PageTemplate>
    );
};

export default ClientDetails;
