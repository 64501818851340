import { ProjectStatusEnum } from '@api/enum';
import { PermissionEnum } from '@api/enum/permission.enum';
import { IProject } from '@api/models';
import CustomMultiSelect from '@components/FormFields/CustomMultiSelect';
import CustomSwitch from '@components/FormFields/CustomSwitch';
import RequiredMark from '@components/FormFields/RequiredMark';
import useAuthPermission from '@hooks/use-auth-permission';
import { Button, Card, Label, LinearProgress } from '@hyperflake/react-ui-library';
import { FormHelperText } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { ErrorMessage, Form, Formik } from 'formik';
import upperCase from 'lodash.uppercase';
import { FORMATTED_ASSET_LIST } from 'modules/projects/helpers/projects.constants';
import useProjectData from 'modules/projects/hooks/useProjectData';
import useProjectUpdateForm from 'modules/projects/hooks/useProjectUpdateForm';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { FormikInput, FormikSelect } from 'modules/shared/features/formik';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ProjectRendersDrawer from '../ProjectRendersDrawer/ProjectRendersDrawer';

interface projectEditFormProps {
    updateProject?: (project: IProject) => void;
    projectId?: string;
    onClose?: () => void;
}

const ProjectDetails = (props: projectEditFormProps) => {
    // const { onClose, projectId, updateProject } = props;
    const { clientId, projectId } = useParams();
    const { project, isLoading } = useProjectData(clientId, projectId);
    const updateProjectFrom = useProjectUpdateForm({ project, clientId });
    const { hasPermission } = useAuthPermission();
    const navigate = useNavigate();

    const handleSubmit = async (values: any) => {
        try {
            const data = await updateProjectFrom.submit(values);
            toast.success('Project Updated Successfully.');
            // navigate(`/clients/${clientId}/projects`);

            // navigate(`/clients/${clientId}/projects?tab=projects&search=${data.name}`); // updateProject(data);

            // onClose();

            // add to client
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');
            console.log(ex);
        }
    };
    const ProjectStatusOptions = useMemo(() => {
        return Object.values(ProjectStatusEnum).map((projectStatus) => ({
            value: projectStatus as ProjectStatusEnum,
            label: upperCase(projectStatus),
        }));
    }, []);
    const parentProjectOptions = useMemo(() => {
        if (!updateProjectFrom.projectAsParent) return [];
        const temp = updateProjectFrom.projectAsParent
            .filter((proj) => proj.hasCameras === false && proj._id !== projectId)
            .map((project) => ({
                label: project.name,
                value: project._id,
            }));

        return [
            {
                label: 'Select an option',
                value: '',
            },
            ...temp,
        ];
    }, [updateProjectFrom.projectAsParent]);

    return (
        <PageTemplate>
            {/* <PageHeading>Edit Project</PageHeading> */}
            {isLoading ? (
                <LinearProgress color="primary" />
            ) : (
                <div>
                    <Formik
                        initialValues={updateProjectFrom.initialValues}
                        enableReinitialize
                        validationSchema={updateProjectFrom.schema}
                        onSubmit={handleSubmit}
                    >
                        {({}) => (
                            <Form>
                                <fieldset disabled={!hasPermission(PermissionEnum.PROJECT_UPDATE)}>
                                    <div>
                                        {/* name */}
                                        <Card className="">
                                            <Card.Header>Basic Details</Card.Header>
                                            <Card.Body className="grid grid-cols-2 gap-4">
                                                <div className="">
                                                    <Label>
                                                        Name <RequiredMark />
                                                    </Label>
                                                    <FormikInput type="text" name="name" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="name" />
                                                    </FormHelperText>
                                                </div>

                                                {/* location.name */}
                                                <div>
                                                    <Label>
                                                        Location <RequiredMark />
                                                    </Label>
                                                    <FormikInput type="text" name="location.name" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="location.name" />
                                                    </FormHelperText>
                                                </div>

                                                {/* location.latitude */}
                                                <div className="">
                                                    <Label>Latitude</Label>
                                                    <FormikInput type="text" name="location.latitude" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="location.latitude" />
                                                    </FormHelperText>
                                                </div>

                                                {/* location.longitude */}
                                                <div className="">
                                                    <Label>Longitude</Label>
                                                    <FormikInput type="text" name="location.longitude" />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="location.longitude" />
                                                    </FormHelperText>
                                                </div>

                                                {/* assets */}
                                                <div>
                                                    <Label>Assets</Label>
                                                    <CustomMultiSelect
                                                        name="assets"
                                                        options={FORMATTED_ASSET_LIST}
                                                        className=""
                                                    />
                                                    <FormHelperText variant={'error'}>
                                                        <ErrorMessage name="assets" />
                                                    </FormHelperText>
                                                    {/* Has Assets */}
                                                    <div className="">
                                                        <CustomSwitch name="hasCameras" label="Has Assets" />
                                                        <FormHelperText variant={'error'} />
                                                    </div>
                                                </div>
                                                <div>
                                                    {/* parentId */}
                                                    <div>
                                                        <div>
                                                            <Label>Parent</Label>
                                                            <div className="flex gap-2">
                                                                <FormikSelect
                                                                    name="parentId"
                                                                    options={parentProjectOptions}
                                                                />
                                                                {updateProjectFrom.projectAsParentIsloading && (
                                                                    <CircularProgress size={14} />
                                                                )}
                                                            </div>
                                                            <FormHelperText variant={'error'}>
                                                                <ErrorMessage name="parentId" />
                                                            </FormHelperText>
                                                        </div>
                                                        <div>
                                                            <div>
                                                                <Label>Status</Label>
                                                                <FormikSelect
                                                                    name="status"
                                                                    options={ProjectStatusOptions}
                                                                />
                                                                <FormHelperText variant={'error'}>
                                                                    <ErrorMessage name="status" />
                                                                </FormHelperText>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <Button
                                                    type="submit"
                                                    size={'lg'}
                                                    className="mt-4 w-28"
                                                    disabled={updateProjectFrom.isSubmitting}
                                                >
                                                    {updateProjectFrom.isSubmitting ? (
                                                        <CircularProgress size={20} color={'inherit'} />
                                                    ) : (
                                                        'Submit'
                                                    )}
                                                </Button>
                                            </Card.Body>
                                        </Card>
                                    </div>
                                </fieldset>
                            </Form>
                        )}
                    </Formik>
                    <Card className="mt-4">
                        <Card.Header>Project Renders</Card.Header>
                        <Card.Body>
                            <ProjectRendersDrawer />
                        </Card.Body>
                    </Card>
                </div>
            )}
        </PageTemplate>
    );
};

export default ProjectDetails;
