import capitalize from 'lodash.capitalize';
import { UserRoleEnum } from '@api/enum/user-role.enum';
import { UserThemeEnum } from '@api/enum/user-theme.enum';
import { UserStatusEnum } from '@api/enum/user-status.enum';
import { timezoneList } from 'modules/shared/helpers/shared.constants';

export const timezoneOption = [
    {
        label: 'Select Timezone',
        value: '',
    },
    ...timezoneList.map((tz) => ({
        label: tz.label,
        value: tz.value,
    })),
];

export const themeOption = Object.values(UserThemeEnum).map((theme) => ({
    label: theme,
    value: theme,
}));

export const roleOption = Object.values(UserRoleEnum).map((role) => ({
    label: capitalize(role),
    value: role,
}));

export const statusOption = Object.values(UserStatusEnum).map((status) => ({
    label: capitalize(status),
    value: status,
}));

export const FilterValues = Object.freeze({
    ALL: 'ALL',
    ENABLED: UserStatusEnum.ENABLED,
    DISABLED: UserStatusEnum.DISABLED,
});
