import { IInternalApplications } from '@api/models';
import { getInternalApplicationUrlById } from '@api/services/internal-applications.service';
import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { GalleryCard } from 'modules/shared/features/content';
import { toast } from 'react-toastify';
import { Button, CircularProgress } from '@hyperflake/react-ui-library';
import { CopyIcon, DownloadIcon } from '@icon/index';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import { imageDownloaderUrls } from 'modules/shared/helpers/imageDownloaderUrls';

interface ImageDownloaderProps {
    data: IInternalApplications;
}

const InternalApplicationCard = (props: ImageDownloaderProps) => {
    const { data } = props;
    // const [macUrl, setMacUrl] = useState('');
    // const [windowsUrl, setWindowsUrl] = useState('');
    // const internalApplicationMutation = useMutation((os: string) => getInternalApplicationUrlById(data._id, os));

    // const handleMacOnClick = async () => {
    //     const internalApplicationWithUrl = await internalApplicationMutation.mutateAsync('mac');
    //     setMacUrl(internalApplicationWithUrl.url);
    // };

    // const handleWindowsOnClick = async () => {
    //     const internalApplicationWithUrl = await internalApplicationMutation.mutateAsync('windows');
    //     setWindowsUrl(internalApplicationWithUrl.url);
    // };

    const copyToClipboard = (url: string) => {
        navigator.clipboard.writeText(url);
        toast.success('Link Copied');
        // setMacUrl('');
        // setWindowsUrl('');
    };

    const handleDownload = (url: string) => {
        const link = document.createElement('a');
        link.href = url;

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success('Downloading');
        // setMacUrl('');
        // setWindowsUrl('');
    };

    return (
        <div className="relative">
            <GalleryCard>
                <div className="p-2">
                    <img
                        className="max-h-10 max-w-[140px] mt-4"
                        src="/internal-applications/image-downloader.svg"
                        alt=""
                    />
                    <div className="font-bold mt-4">{data.name}</div>
                    <p className="mt-4 text-sm">{data.description}</p>
                    <div className="flex justify-end space-x-2 mt-4">
                        <Dropdown placement="bottom-end">
                            <DropdownTrigger
                            //  className="w-6 h-6 grid place-items-center rounded-[4px] bg-floating-color image-overlay-button-shadow hover-transition hover:brightness-90"
                            >
                                <div
                                    className={`relative inline-flex items-center text-xs px-2 py-1 rounded-full font-semibold bg-gray-800 text-white cursor-pointer`}
                                    // onClick={handleMacOnClick}
                                >
                                    <>
                                        Download
                                        <img className="ml-2" src="/icons/apple.svg" alt="" width={15} height={15} />
                                    </>
                                </div>
                            </DropdownTrigger>

                            <DropdownList className="min-w-[100px]">
                                <DropdownItem
                                    onClick={() => copyToClipboard(import.meta.env.VITE_APP_IMAGE_DOWNLOADER_MAC_URL)}
                                >
                                    Copy Link
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() => handleDownload(import.meta.env.VITE_APP_IMAGE_DOWNLOADER_MAC_URL)}
                                >
                                    Download
                                </DropdownItem>
                            </DropdownList>
                        </Dropdown>

                        <Dropdown placement="bottom-end">
                            <DropdownTrigger
                            //  className="w-6 h-6 grid place-items-center rounded-[4px] bg-floating-color image-overlay-button-shadow hover-transition hover:brightness-90"
                            >
                                <div
                                    className={`relative inline-flex items-center text-xs px-2 py-1 rounded-full font-semibold bg-blue-500 text-white cursor-pointer`}
                                    // onClick={handleWindowsOnClick}
                                >
                                    <>
                                        Download
                                        <img className="ml-2" src="/icons/windows.svg" alt="" />
                                    </>
                                </div>
                            </DropdownTrigger>

                            <DropdownList className="min-w-[100px]">
                                <DropdownItem
                                    onClick={() =>
                                        copyToClipboard(import.meta.env.VITE_APP_IMAGE_DOWNLOADER_WINDOWS_URL)
                                    }
                                >
                                    Copy Link
                                </DropdownItem>
                                <DropdownItem
                                    onClick={() =>
                                        handleDownload(import.meta.env.VITE_APP_IMAGE_DOWNLOADER_WINDOWS_URL)
                                    }
                                >
                                    Download
                                </DropdownItem>
                            </DropdownList>
                        </Dropdown>
                    </div>
                </div>
            </GalleryCard>
        </div>
    );
};

export default InternalApplicationCard;
