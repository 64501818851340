// import { ICameraBimModel } from 'api/models';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { initViewer, loadModel } from '../helpers/camera-bim-viewer.utils';
import { ICameraBimModel } from '@api/models';

const MULTIPLIER = 2.5;
const AVAILABLE_EXTENSIONS = ['Autodesk.Measure', 'Autodesk.Explode', 'Autodesk.ModelStructure'];
const TOOLS = ['measure', 'calibration'];

interface UseBimViewerProps extends ICameraBimModel {}

const useBimViewer = (options: UseBimViewerProps) => {
    const { accessToken, urn, state } = options;

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isToolActive, setIsToolActive] = useState<boolean>(false);
    const [isModelActive, setIsModelActive] = useState<boolean>(false);
    const [showSlider, setShowSlider] = useState(false);

    const toggleSliderVisibility = () => setShowSlider(!showSlider);

    const toolbarRef = useRef(null);
    const forgeViewerRef = useRef(null);
    const modelStructurePanelRef = useRef(null);

    const [viewer, setViewer] = useState(null);

    const toolbarRefSetter = useCallback((node: HTMLElement) => {
        if (!node) return;

        toolbarRef.current = node;
    }, []);

    const forgeViewerRefSetter = useCallback((node: HTMLElement) => {
        if (!node) return;

        forgeViewerRef.current = node;
    }, []);

    const modelStructurePanelRefSetter = useCallback((node: HTMLElement) => {
        if (!node) return;

        modelStructurePanelRef.current = node;
    }, []);

    // const createCustomButton = (viewer: Autodesk.Viewing.GuiViewer3D, toggleSliderVisibility: () => void) => {
    //     const button = new Autodesk.Viewing.UI.Button('my-custom-button-id');

    //     button.setToolTip('Opacity Button');

    //     button.setIcon('my-custom-icon'); // Ensure you have an icon class defined in your CSS

    //     button.onClick = toggleSliderVisibility;

    //     // Add the button to the toolbar
    //     const toolbar = viewer.getToolbar(true); // 'true' forces the toolbar to create if it does not exist

    //     const controlGroup = toolbar.getControl('my-custom-control-group') as Autodesk.Viewing.UI.ControlGroup;

    //     if (!controlGroup) {
    //         const newControlGroup = new Autodesk.Viewing.UI.ControlGroup('my-custom-control-group');

    //         toolbar.addControl(newControlGroup);

    //         newControlGroup.addControl(button);
    //     } else {
    //         controlGroup.addControl(button);
    //     }
    // };

    useEffect(() => {
        const originalDevicePixelRatio = window.devicePixelRatio;

        const beforeLoad = (viewer: Autodesk.Viewing.GuiViewer3D) => {
            // viewer.navigation.toOrthographic();
            window.devicePixelRatio = originalDevicePixelRatio * MULTIPLIER;

            viewer.setQualityLevel(false, false);
            viewer.setGroundShadow(false);
            viewer.setGroundReflection(false);
            viewer.setProgressiveRendering(true);
            viewer.setEnvMapBackground(false);
            viewer.toolbar.setVisible(false);
        };

        const afterLoad = (viewer: Autodesk.Viewing.GuiViewer3D) => {
            const settingsTools = viewer.toolbar.getControl('settingsTools') as Autodesk.Viewing.UI.ControlGroup;

            // To remove setting Tools from Toolbar

            // if (settingsTools && process.env.VITE_APP_ENVIRONMENT !== 'development') {
            //     settingsTools.removeControl('toolbar-settingsTool');

            //     settingsTools.removeControl('toolbar-fullscreenTool');
            // }

            // Object.keys(viewer.getLoadedExtensions()).forEach((extName) => {
            //     if (!AVAILABLE_EXTENSIONS.includes(extName) && process.env.NODE_ENV !== 'development') {
            //         viewer.unloadExtension(extName);
            //     }
            // });

            if (state) {
                viewer.restoreState(state, null, true);
                viewer.autocam.setCurrentViewAsHome();
            }

            viewer.setEnvMapBackground(false);
            // viewer.setBackgroundColor(0, 0, 0, 0, 0, 0);

            toolbarRef.current.appendChild(viewer.toolbar.container);

            // const msp = new Autodesk.Viewing.UI.ModelStructurePanel(
            //     modelStructurePanelRef.current,
            //     'ViewerModelStructurePanel',
            //     'Model Browser'
            // );

            // viewer.setModelStructurePanel(msp);

            viewer.setNavigationLock(true);

            viewer.toolbar.setVisible(true);

            // viewer.unloadExtension('Autodesk.FullScreen');

            setIsLoading(false);

            // if (process.env.VITE_APP_ENVIRONMENT === 'development') {
            (global as any).viewer = viewer;
            // }
        };

        const init = async () => {
            const viewer = await initViewer(forgeViewerRef.current, {
                accessToken: accessToken,
            });

            await loadModel(viewer, urn);

            // await Promise.all([
            //     loadExplodeExtension(viewer),
            //     loadMeasureExtension(viewer),
            //     loadModelStructureExtension(viewer),
            // ]);

            beforeLoad(viewer);

            viewer.addEventListener(Autodesk.Viewing.TEXTURES_LOADED_EVENT, function (e) {
                console.log('TEXTURES_LOADED_EVENT');

                afterLoad(viewer);
            });

            viewer.addEventListener(Autodesk.Viewing.GEOMETRY_LOADED_EVENT, function (e) {
                console.log('GEOMETRY_LOADED_EVENT');

                const toolControl = document.getElementById('toolbar-calibrationTool');

                // if (toolControl && process.env.NODE_ENV !== 'development') {
                //     toolControl.parentNode.removeChild(toolControl);
                // }

                afterLoad(viewer);
            });

            viewer.addEventListener(Autodesk.Viewing.VIEWER_RESIZE_EVENT, function (e) {
                console.log('resize event fired');

                window.devicePixelRatio = originalDevicePixelRatio;
            });

            viewer.addEventListener(Autodesk.Viewing.TOOL_CHANGE_EVENT, (e) => {
                if (TOOLS.includes(e.toolName)) {
                    setIsToolActive(e.active);
                }
            });

            viewer.addEventListener(Autodesk.Viewing.EXTENSION_ACTIVATED_EVENT, (e) => {
                if (e.extensionId === 'Autodesk.ModelStructure') {
                    setIsModelActive(true);

                    const crossIcons = document.getElementsByClassName(
                        'docking-panel-close'
                    ) as HTMLCollectionOf<HTMLElement>;

                    Array.from(crossIcons).forEach((crossIcon: HTMLElement) => {
                        // if (process.env.VITE_APP_ENVIRONMENT !== 'development') {
                        crossIcon.style.display = 'none';
                        // }
                    });
                }
            });

            viewer.addEventListener(Autodesk.Viewing.EXTENSION_DEACTIVATED_EVENT, (e) => {
                if (e.extensionId === 'Autodesk.ModelStructure') {
                    setIsModelActive(false);
                }
            });

            // viewerRef.current = viewer;

            setViewer(viewer);
        };

        init();

        return () => {
            // if (viewerRef.current) {
            console.log('shutdown called');

            // viewerRef.current?.finish();
            // viewerRef.current = null;
            Autodesk.Viewing.shutdown();
            // }
        };
    }, [accessToken, state, urn, showSlider]);

    const refs = useMemo(() => {
        return {
            toolbar: {
                setReference: toolbarRefSetter,
                reference: toolbarRef,
            },
            viewer: {
                setReference: forgeViewerRefSetter,
                reference: forgeViewerRef,
            },
            modelStructurePanel: {
                setReference: modelStructurePanelRefSetter,
                reference: modelStructurePanelRef,
            },
        };
    }, [forgeViewerRefSetter, toolbarRefSetter, modelStructurePanelRefSetter]);
    return {
        isToolActive,
        isModelActive,
        showSlider,
        isLoading,
        viewer,
        refs,
    };
};

export default useBimViewer;
