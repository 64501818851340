import { ICameraCCTV } from '@api/models/camera-cctv.model';
import CustomSelect from '@components/FormFields/CustomSelect';
import { Button, Card, Drawer } from '@hyperflake/react-ui-library';
import { FormHelperText, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useQuery } from '@tanstack/react-query';
import { ErrorMessage, Form, Formik } from 'formik';
import http from 'httpConfig/http';
import useCctvCameraAddForm from 'modules/cctv-cameras/hooks/useCctvCameraAddForm';
import FormikDatePicker from 'modules/shared/components/FormFields/FormikDatePicker';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import { PageHeading, PageTemplate } from 'modules/shared/features/content';
import { FormikInput } from 'modules/shared/features/formik';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ICctvCameraAddForm {}

const CctvCameraAddDrawerForm = (props: ICctvCameraAddForm) => {
    // const { open, onClose, onAddCctvCamera } = props;

    // const queryClient = useQueryClient();
    const { clientId, projectId } = useParams();
    const addCctvCameraAddForm = useCctvCameraAddForm(clientId, projectId);
    const navigate = useNavigate();

    const { data: storages, isLoading: storageIsLoading } = useQuery<string[]>(
        ['clients', clientId, 'projects', projectId, 'cc-cameras', 'storage_names'],
        async () => {
            const { data } = await http.get(
                `${
                    import.meta.env.VITE_APP_API_URL
                }/${getResellerIdFromRedux()}/clients/${clientId}/projects/${projectId}/cameras/storage-names`
            );
            return data;
        }
    );

    const transformData = (values: any) => {
        return {
            ...values,
            installationDate: moment(values.installationDate).format('YYYYMMDD'),
        };
    };

    const handleSubmit = async (values: any) => {
        try {
            const tranformedData = transformData(values);
            const data = await addCctvCameraAddForm.submit(tranformedData);
            toast.success('CCtv Camera Added Successfully');

            navigate(`/clients/${clientId}/projects/${projectId}/cctv-cameras`);
        } catch (e: any) {
            console.log(e);
            toast.error('Oops something went wrong.');
        }
    };

    const storageOption = useMemo(() => {
        if (!storages) return [];
        return [...storages.map((val: string) => ({ label: val, value: val }))];
    }, [storages]);

    // const { hasAdminPermission } = useAuthPermission();

    // if (!hasAdminPermission) return <AdminPermission formTitle="Live Camera" />;

    return (
        // <Drawer open={open} onClose={onClose} title="Add Live Camera">
        <PageTemplate>
            <PageHeading>Add Live Camera</PageHeading>
            <div>
                <Formik
                    initialValues={addCctvCameraAddForm.initialValues}
                    enableReinitialize
                    validationSchema={addCctvCameraAddForm.schema}
                    onSubmit={handleSubmit}
                >
                    {() => (
                        <Form>
                            <Card className="mt-4">
                                <Card.Header>Basic Details</Card.Header>
                                <Card.Body>
                                    <div className="grid grid-cols-2 gap-4">
                                        {/* name */}
                                        <div className="">
                                            <Label>
                                                Name <RequiredMark />
                                            </Label>
                                            <FormikInput type="text" name="name" />
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="name" />
                                            </FormHelperText>
                                        </div>
                                        {/* installation Date */}
                                        <div className="">
                                            <Label>
                                                Installation Date <RequiredMark />
                                            </Label>
                                            <FormikDatePicker name="installationDate" maxDate={new Date()} />
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="installationDate" />
                                            </FormHelperText>
                                        </div>
                                        {/* Streaming Url */}
                                        <div>
                                            <Label>
                                                Streaming Url <RequiredMark />
                                            </Label>
                                            <div className="flex gap-2">
                                                <FormikInput name="streamingUrl" />
                                            </div>
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="streamingUrl" />
                                            </FormHelperText>
                                        </div>
                                        {/* qLiveUnit */}
                                        <div>
                                            <Label>
                                                QLive Unit <RequiredMark />
                                            </Label>
                                            <div className="flex gap-2">
                                                <FormikInput name="qLiveUnit" />
                                            </div>
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="qLiveUnit" />
                                            </FormHelperText>
                                        </div>
                                        {/* storage */}
                                        <div>
                                            <Label>
                                                Storage <RequiredMark />
                                            </Label>
                                            <div className="flex gap-2">
                                                <CustomSelect name="storage" options={storageOption} />
                                                {storageIsLoading && <CircularProgress size={14} />}
                                            </div>
                                            <FormHelperText variant={'error'}>
                                                <ErrorMessage name="storage" />
                                            </FormHelperText>
                                        </div>
                                    </div>
                                    <div className="mt-4">
                                        <h5>Location</h5>
                                        <div className="grid grid-cols-2 gap-4 mt-4">
                                            <div className="">
                                                <Label>Latitude</Label>
                                                <FormikInput type="text" name="location.latitude" />
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="location.latitude" />
                                                </FormHelperText>
                                            </div>
                                            <div className="">
                                                <Label>Longitude</Label>
                                                <FormikInput type="text" name="location.longitude" />
                                                <FormHelperText variant={'error'}>
                                                    <ErrorMessage name="location.longitude" />
                                                </FormHelperText>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>

                            <Button
                                type="submit"
                                size={'lg'}
                                className="mt-4 w-28"
                                disabled={addCctvCameraAddForm.isSubmitting}
                            >
                                {addCctvCameraAddForm.isSubmitting ? (
                                    <CircularProgress size={20} color={'inherit'} />
                                ) : (
                                    'Submit'
                                )}
                            </Button>
                        </Form>
                    )}
                </Formik>
            </div>
        </PageTemplate>
        // </Drawer>
    );
};

export default CctvCameraAddDrawerForm;
