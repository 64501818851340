// import { BackButton } from 'library/buttons';
// import { DocumentTitle } from 'library/head';
// import { AppHeader } from 'library/header';
// import useCameraViewImages from 'modules/dashboard/hooks/use-camera-view';
// import useCameraLayoutContext from 'modules/dashboard/layouts/camera/hooks/use-camera-layout-context';
// import ScrollIntoView from 'modules/shared/components/helpers/ScrollIntoView';
// import { IllustrationView, NoImagesIllustration } from 'modules/shared/features/illustration';
import NoImagesIllustration from '@components/Illustrations/NoImagesIllustration';
import IllustrationView from '@components/IllustrationView/IllustrationView';
import { LinearProgress } from '@hyperflake/react-ui-library';
import { BackButton } from '@library/buttons';
import useCameraData from 'modules/cameras/hooks/useCameraData';
import useCameraViewImages from 'modules/reseller-cameras/hooks/use-camera-view';
import { CameraBimViewer, useBimScriptLoader, useCameraBimData } from 'modules/shared/features/camera-bim-viewer';
import { useParams } from 'react-router-dom';
// import CameraBimViewerLoading from './Loading';
// import { CameraBimViewer, useBimScriptLoader, useCameraBimData } from 'modules/dashboard/features/camera-bim-viewer';

const CameraBimViewerPage = () => {
    const { clientId, projectId, cameraId } = useParams();

    // const { camera, project, isCameraQueryLoading } = useCameraLayoutContext();
    const cameraData = useCameraData(clientId, projectId, cameraId);

    const {
        isInitialLoading,
        isImagesQueryLoading,
        images,
        selectedImage,
        changeSelectedImage,
        selectedDate,
        changeSelectedDate,
        startDate,
        endDate,
    } = useCameraViewImages(projectId, cameraId);

    const bimData = useCameraBimData(clientId, projectId, cameraId);

    const { isLoading: isScriptLoading } = useBimScriptLoader();

    const renderView = () => {
        if (bimData.error)
            return (
                <IllustrationView
                    className="mt-[15vh]"
                    illustration={<NoImagesIllustration />}
                    title="Bim not activated"
                    message="This camera doesn't have bim activated."
                />
            );

        if (!cameraData.camera.hasImages)
            return (
                <IllustrationView
                    className="mt-[15vh]"
                    illustration={<NoImagesIllustration />}
                    title="No Images yet"
                    message="No results found"
                />
            );

        return (
            <CameraBimViewer
                images={images}
                selectedImage={selectedImage}
                onSelectedImageChange={changeSelectedImage}
                selectedDate={selectedDate}
                onSelectedDateChange={changeSelectedDate}
                startDate={startDate}
                endDate={endDate}
                isLoading={isImagesQueryLoading}
                bimDetails={bimDetails}
            />
        );
    };

    // if (isCameraQueryLoading || isInitialLoading || bimData.isLoading || isScriptLoading)
    //     return <CameraBimViewerLoading />;
    if (isScriptLoading || cameraData.isLoading || bimData.isLoading || isInitialLoading) return <LinearProgress />;

    const bimDetails = bimData.details;

    return (
        <>
            {renderView()}
            {/* <DocumentTitle>
                Bim Viewer | {camera?.name} - {project?.name}
            </DocumentTitle>

            <ScrollIntoView>
                <AppHeader headerComponent={<BackButton />} title={`${camera.name} - ${project.name} Bim Viewer`} />

                {renderView()}
            </ScrollIntoView> */}
        </>
    );
};

export default CameraBimViewerPage;
