/**
 * Initializes the runtime for communicating with the Model Derivative service, and creates a new instance of viewer.
 * @async
 * @param {HTMLElement} container Container that will host the viewer.
 * @param {object} config Additional configuration options for the new viewer instance.
 * @returns {Promise<Autodesk.Viewing.GuiViewer3D>} New viewer instance.
 */
interface ViewerOptions {
    accessToken: string;
}
export const initViewer = async (
    container: HTMLElement,
    options: ViewerOptions
): Promise<Autodesk.Viewing.GuiViewer3D> => {
    const { accessToken } = options;

    return new Promise((resolve, reject) => {
        Autodesk.Viewing.Initializer(
            {
                accessToken: accessToken,
            },
            () => {
                const viewer = new Autodesk.Viewing.GuiViewer3D(container);
                const startedCode = viewer.start();

                if (startedCode > 0) {
                    console.error('Failed to create a Viewer: WebGL not supported.');

                    return reject(new Error('Failed to create a Viewer: WebGL not supported.'));
                }

                return resolve(viewer);
            }
        );
    });
};

/**
 * Loads specific model into the viewer.
 * @param {Autodesk.Viewing.GuiViewer3D} viewer Target viewer.
 * @param {string} urn URN of the model in the Model Derivative service.
 */
export const loadModel = async (viewer: Autodesk.Viewing.GuiViewer3D, urn: string): Promise<Autodesk.Viewing.Model> => {
    return new Promise((resolve, reject) => {
        Autodesk.Viewing.Document.load(
            'urn:' + urn,
            (doc) => {
                // onDocumentLoadSuccess callback
                const viewable = doc.getRoot().search({
                    role: '3d',
                })[0];

                const model = viewer.loadDocumentNode(doc, viewable);

                resolve(model);
            },
            (code, message, errors) => {
                // onDocumentLoadFailure callback
                console.error(code, message, errors);

                reject(new Error('Could not load model. See console for more details.'));
            }
        );
    });
};

/**
 * Loads Explode Extension.
 * @param {Autodesk.Viewing.GuiViewer3D} viewer Target viewer.
 */
export const loadExplodeExtension = async (viewer: Autodesk.Viewing.GuiViewer3D) => {
    let extension = viewer.getExtension('Autodesk.Explode');

    if (!extension) {
        extension = await viewer.loadExtension('Autodesk.Explode');
    }

    return extension;
};

/**
 * Loads Measure Extension.
 * @param {Autodesk.Viewing.Viewer3D} viewer Target viewer.
 */
export const loadMeasureExtension = async (viewer: Autodesk.Viewing.Viewer3D) => {
    let extension = viewer.getExtension('Autodesk.Measure');

    if (!extension) {
        extension = await viewer.loadExtension('Autodesk.Measure');
    }

    return extension;
};

/**
 * Loads ModelStructure Extension.
 * @param {Autodesk.Viewing.Viewer3D} viewer Target viewer.
 */
export const loadModelStructureExtension = async (viewer: Autodesk.Viewing.Viewer3D) => {
    let extension = viewer.getExtension('Autodesk.ModelStructure');

    if (!extension) {
        extension = await viewer.loadExtension('Autodesk.ModelStructure');
    }

    return extension;
};
