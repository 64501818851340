import {
    autoUpdate,
    flip,
    FloatingFocusManager,
    offset,
    size,
    useClick,
    useDismiss,
    useFloating,
    useId,
    useInteractions,
    useListNavigation,
    useRole,
} from '@floating-ui/react';

import { FC, ForwardedRef, forwardRef, HTMLProps, ReactNode, useRef, useState } from 'react';

interface OptionProps extends HTMLProps<HTMLDivElement> {
    children: ReactNode;
    isActive: boolean;
    isSelected: boolean;
    ref?: ForwardedRef<any>;
}

const Option = forwardRef((props: OptionProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { children, isActive, isSelected, ...rest } = props;

    const id = useId();

    return (
        <div
            ref={ref}
            id={id}
            role="option"
            aria-selected={isActive && isSelected}
            tabIndex={isActive ? 0 : -1}
            className={[
                'floating-list-item',
                isActive ? 'floating-list-item-hover' : '',
                isSelected ? 'floating-list-item-active' : '',
            ].join(' ')}
            {...rest}
        >
            {children}
        </div>
    );
});

/**
 * SelectDropdown
 */
interface SelectDropdownProps {
    value?: string | number;
    onChange?: (value: string | number) => void;
    options?: { label: string; value: string | number }[];
    className?: string;
    render?: (label: string) => ReactNode;
}
const SelectDropdown: FC<SelectDropdownProps> = (props) => {
    const { value: controlledValue, onChange, options, className, render } = props;

    const [open, setOpen] = useState<boolean>(false);
    const [activeIndex, setActiveIndex] = useState<number>(0);

    const [unControlledValue, setUnControlledValue] = useState<string | number>(
        options.length > 0 ? options[0].value : null
    );

    const value = controlledValue !== undefined ? controlledValue : unControlledValue;
    const setValue = controlledValue !== undefined ? undefined : setUnControlledValue;
    const selectedIndex = options.findIndex((opt) => opt.value === value);

    const selectedItemLabel = options.length > 0 ? options[selectedIndex]?.label : undefined;

    const { x, y, strategy, refs, context } = useFloating({
        whileElementsMounted: autoUpdate,
        placement: 'bottom-end',
        middleware: [
            flip(),
            offset(4),
            size({
                apply({ rects, elements, availableHeight, availableWidth }) {
                    const width = Math.max(rects.floating.width, rects.reference.width);
                    const height = Math.min(availableHeight, 400);

                    Object.assign(elements.floating.style, {
                        maxHeight: `${height}px`,
                        width: `${width}px`,
                    });
                },
            }),
        ],
        open: open,
        onOpenChange: setOpen,
    });

    const listRef = useRef<Array<HTMLElement>>([]);

    const click = useClick(context);
    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'listbox' });

    const listNavigation = useListNavigation(context, {
        listRef,
        activeIndex,
        selectedIndex,
        onNavigate: setActiveIndex,
        virtual: true,
    });

    const { getReferenceProps, getFloatingProps, getItemProps } = useInteractions([
        click,
        role,
        dismiss,
        listNavigation,
    ]);

    const handleSelect = (index: number) => {
        const selectedOption = options[index];

        setValue?.(selectedOption.value);

        onChange?.(selectedOption.value);

        setOpen(false);
    };

    return (
        <>
            <div
                className={['cursor-pointer', className].join(' ')}
                aria-label={selectedItemLabel}
                {...getReferenceProps({
                    ref: refs.setReference,
                })}
            >
                <div className="overflow-hidden text-ellipsis whitespace-nowrap w-full">
                    {render(selectedItemLabel)}
                </div>
            </div>

            {open && (
                <FloatingFocusManager context={context}>
                    <div
                        ref={refs.setFloating}
                        style={{
                            position: strategy,
                            top: y ?? 0,
                            left: x ?? 0,
                            width: 'min-content',
                        }}
                        className="floating-list"
                        {...getFloatingProps()}
                    >
                        {options.map((option, i) => (
                            <Option
                                key={option.value}
                                ref={(node) => (listRef.current[i] = node)}
                                isActive={activeIndex === i}
                                isSelected={selectedIndex === i}
                                {...getItemProps({
                                    onClick() {
                                        handleSelect(i);
                                    },

                                    onKeyDown(event) {
                                        if (event.key === 'Enter') {
                                            event.preventDefault();
                                            handleSelect(i);
                                        }

                                        if (event.key === ' ' && !context.dataRef.current.typing) {
                                            event.preventDefault();
                                            handleSelect(i);
                                        }
                                    },
                                })}
                            >
                                {option.label}
                            </Option>
                        ))}
                    </div>
                </FloatingFocusManager>
            )}
        </>
    );
};

SelectDropdown.defaultProps = {
    options: [],
    render: (label: string) => label,
};

export default SelectDropdown;
