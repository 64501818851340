import { getCctvCameraOfProjectById } from '@api/services/cctv-camera.service';
import useDataQuery from '@hooks/use-data-query';

const useCctvCameraData = (clientId: string, projectId: string, cctvCameraId: string) => {
    const cctvCameraQuery = useDataQuery(
        ['cctv-camera-query', clientId, projectId, cctvCameraId],
        () => getCctvCameraOfProjectById(clientId, projectId, cctvCameraId),
        {
            enabled: Boolean(cctvCameraId),
        }
    );

    return {
        isLoading: cctvCameraQuery.isLoading,
        cctvCamera: cctvCameraQuery.data,
        setData: cctvCameraQuery.setData,
    };
};

export default useCctvCameraData;
