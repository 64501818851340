import { useEffect, useRef, useState } from 'react';
import useBimViewer from '../../hooks/useBimViewer';
import useBimZoom from '../../hooks/useBimZoom';
// import { Tooltip } from 'library/tooltip';
import { ICameraBimModel, ICameraImage } from '@api/models';
import OverlayImageSlider from '@components/OverImageSlider/OverlayImageSlider';
import useLoading from '@hooks/use-loading';
import useLoadingText from '@hooks/useLoadingText';
import { Button, IconButton } from '@hyperflake/react-ui-library';
import { LockIcon, SlidersIcon, UnlockIcon } from '@icon/index';
// import { Tooltip } from '@library/tooltip';
import BlurBackgroundImage from 'modules/reseller-cameras/components/ui/BlurBackgroundImage';
import OverlayLoader from 'modules/reseller-cameras/components/ui/OverlayLoader';
import ContentViewer from 'modules/shared/features/ContentViewer/ContentViewer';
import { Tooltip } from '@library/tooltip';
import { Popover } from '@library/popover';
import { useMutation } from '@tanstack/react-query';
import { saveCameraBim } from '@api/services/camera.service';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
// import { Popover } from '@library/popover';

// const state = {
//     viewport: {
//         name: '',
//         eye: [952.0751043505645, -1157.6820376264513, 609.801097972105],
//         target: [-301.74545343225213, 995.7698626168794, -565.1899327253971],
//         up: [-0.21459657129846366, 0.3685721943063145, 0.904490380916885],
//         worldUpVector: [0, 0, 1],
//         pivotPoint: [0, 0, 0],
//         distanceToOrbit: 1598.2775836003345,
//         aspectRatio: 1.4986616947291362,
//         projection: 'perspective',
//         isOrthographic: false,
//         fieldOfView: 44.99999100695533,
//     },
// };

interface CameraBimViewerProps {
    images: ICameraImage[];
    selectedImage: ICameraImage;
    onSelectedImageChange: (image: ICameraImage) => void;
    selectedDate: string;
    onSelectedDateChange: (date: string) => void;
    startDate: string;
    endDate: string;
    isLoading: boolean;
    bimDetails: ICameraBimModel;
}
const CameraBimViewer = (props: CameraBimViewerProps) => {
    const {
        images,
        selectedImage,
        onSelectedImageChange,
        selectedDate,
        onSelectedDateChange,
        startDate,
        endDate,
        isLoading,
        bimDetails,
    } = props;

    // const { user } = useAuthSelector();

    const { clientId, projectId, cameraId } = useParams();

    const imageUrl = selectedImage.urlPreview;
    // const imageUrl = useMemo(() => getPreferedImageUrl(user, selectedImage), [user, selectedImage]);

    const [imgLoading, setImgLoading] = useLoading(imageUrl);
    const [showOpacityRange, setShowOpacityRange] = useState<boolean>(false);
    const [navigationLock, setNavigationLock] = useState<boolean>(true);
    const [showSavePopver, setShowSavePopver] = useState<boolean>(false);

    const { text } = useLoadingText({
        messages: ['Getting everything ready...', 'Putting everything together...', 'Almost there...'],
        delay: 2000,
    });

    const canvasWrapRef = useRef(null);

    const {
        isToolActive,
        isModelActive,
        // showSlider,
        viewer,
        refs,
        isLoading: isBimLoading,
    } = useBimViewer({
        urn: bimDetails.urn,
        accessToken: bimDetails.accessToken,
        expiresIn: bimDetails.expiresIn,
        state: bimDetails?.state,
    });

    const [state, setState] = useState({ x: 0, y: 0, k: 1 });

    const { refs: zoomRefs } = useBimZoom({
        state,
        setState,
    });

    useEffect(() => {
        if (viewer) {
            canvasWrapRef.current.appendChild(viewer.canvasWrap);
            // viewer.canvasWrap.style.aspectRatio = selectedImage?.resolution.width / selectedImage?.resolution.height;
        }
    }, [viewer]);

    useEffect(() => {
        if (viewer) {
            viewer.setNavigationLock(navigationLock);
        }
    }, [navigationLock]);

    useEffect(() => {
        if (viewer) {
            // console.log('In---', viewer);
            // viewer.canvasWrap.style.transform = `translate(${state.x}px, ${state.y}px) scale(${state.k})`;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.k, state.x, state.y, viewer]);

    const [showImageSlider, setShowImageSlider] = useState<boolean>(false);
    const [opacityValue, setOpacityValue] = useState<Number>(0.6);

    const cameraBimMutation = useMutation((payload: any) => saveCameraBim(clientId, projectId, cameraId, payload));

    const handleSaveBim = async () => {
        const viewport = viewer.getState({ viewport: true });

        try {
            await cameraBimMutation.mutateAsync(viewport);

            setShowSavePopver(false);

            toast.success('Bim updated successfully.');
        } catch (err: any) {
            toast.error(err.data.message || 'Something went wrong.');
        }
    };

    return (
        <>
            <ContentViewer className="h-full">
                <div className="relative overflow-hidden">
                    <div className="relative">
                        <BlurBackgroundImage src={imageUrl}>
                            <div className="relative">
                                <div
                                    ref={zoomRefs.container.setReference}
                                    className="relative h-[calc(100vh-var(--header-height)-var(--image-slider-height))] md:h-[calc(100vh-var(--header-height))]"
                                >
                                    <div
                                        className="max-h-full"
                                        ref={zoomRefs.content.setReference}
                                        style={{
                                            aspectRatio:
                                                selectedImage?.resolution.width / selectedImage?.resolution.height,
                                        }}
                                    >
                                        <img
                                            className="w-full h-full"
                                            src={imageUrl}
                                            // src="/dummy-assets/20231022230000.jpg"
                                            alt=""
                                            onLoad={() => setImgLoading(false)}
                                            // style={{ opacity: 0.4, pointerEvents: 'none' }}
                                        />
                                    </div>
                                </div>

                                <div
                                    className="absolute top-0 left-0 w-full h-[calc(100vh-var(--header-height)-var(--image-slider-height))] md:h-[calc(100vh-var(--header-height))]"
                                    style={{
                                        pointerEvents:
                                            isToolActive || isModelActive || !navigationLock ? 'all' : 'none',
                                    }}
                                >
                                    <div
                                        className={`absolute m-auto max-w-full max-h-full inset-0 ${
                                            isModelActive ? 'z-[3]' : 'z-[1]'
                                        } `}
                                        ref={refs.viewer.setReference}
                                        style={{
                                            aspectRatio:
                                                selectedImage?.resolution.width / selectedImage?.resolution.height,
                                        }}
                                    ></div>

                                    <div
                                        className={`absolute ${isToolActive ? 'z-[3]' : 'z-[1]'}  `}
                                        ref={canvasWrapRef}
                                        style={{
                                            // pointerEvents: isToolActive ? 'all' : 'none',
                                            transformOrigin: '0 0',
                                            opacity: +opacityValue,
                                            transform: `translate(${state.x}px, ${state.y}px) scale(${state.k})`,
                                        }}
                                    ></div>
                                </div>
                            </div>
                        </BlurBackgroundImage>

                        <OverlayLoader show={imgLoading} />

                        <OverlayLoader show={isBimLoading}>
                            <div className="text-sm text-grayscale-900 font-medium">{text}</div>
                        </OverlayLoader>

                        {!imageUrl && (
                            <div className="absolute inset-0 flex items-center justify-center bg-background-color text-grayscale-500 font-medium z-[2]">
                                No image on this date
                            </div>
                        )}
                    </div>

                    <div className="adsk-viewing-viewer dark-theme">
                        <div ref={refs.toolbar.setReference}></div>
                        <div ref={refs.modelStructurePanel.setReference}></div>
                    </div>

                    <ContentViewer.Floating position="top-left">
                        <div className="flex items-start gap-4">
                            <Popover
                                className="absolute  top-2"
                                placement="bottom-start"
                                contentClassName="px-6 py-5 rounded-2xl shadow-lg w-[380px]"
                                open={showSavePopver}
                                setOpen={setShowSavePopver}
                                content={
                                    <div>
                                        <p className="text-sm text-grayscale-500">
                                            Are you sure you want to save this change?
                                        </p>

                                        <div className="flex gap-3 mt-4">
                                            <Button
                                                disabled={cameraBimMutation.isLoading}
                                                size="xs"
                                                color="primary"
                                                onClick={handleSaveBim}
                                            >
                                                Save
                                            </Button>
                                            <Button onClick={() => setShowSavePopver(false)} size="xs" variant="shaded">
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                }
                            >
                                {!isBimLoading && <Button className="text-grayscale-600 bg-white">save</Button>}
                            </Popover>
                        </div>
                    </ContentViewer.Floating>

                    <ContentViewer.Floating position="top-right">
                        <div className="relative">
                            {!isBimLoading && (
                                <Tooltip placement="left" content={'Change Opacity'} contentClassName="">
                                    <IconButton
                                        className="bg-white border-b-2"
                                        onClick={() => setShowOpacityRange(!showOpacityRange)}
                                    >
                                        <SlidersIcon width={18} height={22} className="text-grayscale-600" />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {!isBimLoading && (
                                <Tooltip placement="left" content={'Navigation Lock'} contentClassName="max-w-sm ">
                                    <IconButton
                                        className="bg-white border-b-2 mt-4"
                                        onClick={() => setNavigationLock(!navigationLock)}
                                    >
                                        {navigationLock ? (
                                            <LockIcon width={18} height={22} className="text-grayscale-600" />
                                        ) : (
                                            <UnlockIcon width={18} height={22} className="text-grayscale-600" />
                                        )}
                                    </IconButton>
                                </Tooltip>
                            )}
                            {showOpacityRange && (
                                <div className="absolute right-0 cursor-pointer pt-[0.5rem] pb-[0.3rem] px-4 rounded-md bg-white shadow-[0px_0px_2px_rgba(18,40,44,0.12),0px_2px_4px_rgba(18,40,44,0.1)] mt-2">
                                    <input
                                        type="range"
                                        min={0}
                                        max={1}
                                        step={0.1}
                                        value={+opacityValue}
                                        onChange={(e) => setOpacityValue(+e.target.value)}
                                    />
                                </div>
                            )}
                        </div>
                    </ContentViewer.Floating>

                    <OverlayImageSlider
                        show={showImageSlider}
                        onClose={() => setShowImageSlider(false)}
                        images={images}
                        selectedImage={selectedImage}
                        onSelectedImageChange={onSelectedImageChange}
                        selectedDate={selectedDate}
                        onSelectedDateChange={onSelectedDateChange}
                        startDate={startDate}
                        endDate={endDate}
                        isLoading={isLoading}
                    />
                </div>
            </ContentViewer>
        </>
    );
};

export default CameraBimViewer;
