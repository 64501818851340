import { IProject } from '@api/models/project.model';
import { Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { produce } from 'immer';
import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, Drawer } from '@hyperflake/react-ui-library';
import http from 'httpConfig/http';
import { IClient } from '@api/models';

interface ClientDeleteConfirmationDrawerProps {
    open: boolean;
    onClose: () => void;
    client: IClient | null;
}

const ClientDeleteConfirmationDrawer = (props: ClientDeleteConfirmationDrawerProps) => {
    const { open, onClose, client } = props;
    const { clientId } = useParams();
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const deleteClientMutation = useMutation(async (payload: any) => {
        const { data, status } = await http.delete(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${payload.id}`
        );
        return { data, status };
    });

    const handleDelete = async () => {
        if (client === null) return onClose();
        try {
            const { data, status } = await deleteClientMutation.mutateAsync({
                id: client._id,
            });
            console.log({ data, status });
            toast.success('Client Deleted Successfully.');
            // queryClient.setQueryData<IProject[]>(['clients', clientId, 'projects'], (prev) => {
            //     if (!prev) return;

            //     const index = prev.findIndex((c) => c._id === project._id);
            //     return produce(prev, (draft) => {
            //         draft.splice(index, 1);
            //         return draft;
            //     });
            // });
            onClose();
            navigate('/');
        } catch (ex: any) {
            onClose();
            console.log(ex.status);
            if (ex.status === 400) {
                toast.error(ex.data.message);
            } else {
                toast.error(ex?.data?.message || 'Some error occured, please try again.');
                console.log(ex);
            }
        }
    };

    return (
        <Drawer open={open} onClose={onClose} title="Confirm Delete">
            <div>
                <div>
                    <Label className="mt-8 inline-block">
                        The following Client &ldquo;
                        <span className="font-bold">{client?.name}</span>&rdquo; will be delete.
                    </Label>

                    <div className="flex gap-2 mt-2">
                        <Button
                            disabled={deleteClientMutation.isLoading}
                            onClick={() => onClose()}
                            variant="default"
                            color={'primary'}
                        >
                            Cancel
                        </Button>
                        <Button
                            disabled={deleteClientMutation.isLoading}
                            className="w-20"
                            onClick={() => handleDelete()}
                            variant="default"
                            color={'danger'}
                        >
                            {deleteClientMutation.isLoading ? (
                                <CircularProgress className="text-white" size={18} />
                            ) : (
                                'Delete'
                            )}
                        </Button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default ClientDeleteConfirmationDrawer;
