import { ICamera, IClient, IProject } from '@api/models';
import { Tooltip } from '@library/tooltip';
import { GalleryCard, Img } from 'modules/shared/features/content';
import moment from 'moment';
import { ForwardedRef, forwardRef, useMemo } from 'react';

interface ICameraCard {
    camera: ICamera;

    onClick?: () => void;
    isActivator: boolean;
}
const CameraCard = forwardRef((props: ICameraCard, ref: ForwardedRef<HTMLDivElement>) => {
    const {
        camera,
        //  onClick,
        isActivator,
    } = props;

    // const isOlderThan3Days = (date: string) => {
    //     return moment().diff(moment(date, 'YYYYMMDD'), 'days') <= 3;
    // };

    const isActive = useMemo(() => {
        return camera.latestImage
            ? moment().diff(moment(camera.latestImage?.datetime, 'YYYYMMDDHHmmss'), 'hours') <= 24
            : false;
    }, [camera.latestImage]);

    return (
        <div
            // onClick={onClick}
            ref={isActivator ? ref : null}
        >
            <GalleryCard className={`${isActive ? '' : 'bg-danger-100'}`}>
                <GalleryCard.Media>
                    <Img
                        className={`w-full aspect-image rounded-lg object-cover object-center ${
                            !isActive ? 'border-4 border-danger-500 ' : ''
                        }`}
                        src={camera.latestImage?.urlThumb}
                    />
                </GalleryCard.Media>
                <GalleryCard.Title>
                    {(camera?.client as IClient)?.name ? (camera?.client as IClient)?.name : '-'}
                </GalleryCard.Title>
                <GalleryCard.Title>
                    <div>
                        {camera.name}
                        <Tooltip
                            placement="bottom-start"
                            content={
                                <>
                                    This camera has {!isActive && 'not'} uploaded <br /> an
                                    {!isActive && 'y'} image in the past 24 hours.
                                </>
                            }
                            contentClassName="max-w-sm"
                        >
                            {/* <div
                                className={['w-2 h-2 rounded-full  ml-2', isActive ? 'bg-success' : 'bg-danger'].join(
                                    ' '
                                )}
                            ></div> */}
                        </Tooltip>
                    </div>
                </GalleryCard.Title>
                <p>{(camera?.project as IProject)?.name ? (camera?.project as IProject)?.name : '-'}</p>

                <GalleryCard.Footer>
                    <div className="flex flex-wrap gap-4 justify-between">
                        <GalleryCard.FooterItem
                            label="Last Image"
                            content={
                                camera?.latestImage?.datetime ? (
                                    <div className={`${isActive ? 'text-green-500' : 'text-red-500'}`}>
                                        {moment(camera?.latestImage?.datetime, 'YYYYMMDDHHmmss').format(
                                            'h:mm A DD MMM, YY'
                                        )}
                                    </div>
                                ) : (
                                    '-'
                                )
                            }
                        ></GalleryCard.FooterItem>

                        <GalleryCard.FooterItem
                            label="Installed On"
                            content={moment(camera?.installationDate, 'YYYYMMDD').format('DD MMM, YYYY')}
                        ></GalleryCard.FooterItem>
                    </div>
                </GalleryCard.Footer>
            </GalleryCard>
        </div>
    );
});

export default CameraCard;
