// import { CameraCCTVModel, CameraModel, ProjectModel, UserModel } from 'api/models';
// import { Avatar } from 'library/avatar';
// import Img from 'library/media/components/Img';
// import { OverflowTooltip } from 'library/tooltip';
// import { getProjectFirstAsset } from 'modules/dashboard/utils/project-utils';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { SearchTypeEnum } from '../../enums/searchbar.enums';
import Img from '@components/Img/Img';
import OverflowTooltip from '@components/OverflowTooltip/OverflowTooltip';
import { Avatar } from '@hyperflake/react-ui-library';
import { IClient, IProject, IUser } from '@api/models';

/******************************************* ProjectSearchRow *******************************************/

interface ProjectSearchResultRowProps {
    data: IProject;
    onResultClick: () => void;
}
const ProjectSearchResultRow: FC<ProjectSearchResultRowProps> = (props) => {
    const { data: project, onResultClick } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`clients/${(project.client as IClient)._id}/projects/${project._id}/project-details`);

        onResultClick();
    };

    return (
        <div className="floating-list-item py-[10px]" onClick={handleClick}>
            <div>
                <Img
                    parentClassName="w-full h-9"
                    className="w-full h-9 aspect-square rounded-md object-cover object-center"
                    src={project.coverImageUrl}
                />
            </div>
            <OverflowTooltip className="text-sm font-medium text-grayscale-600">{`${
                (project.client as IClient).name
            } / ${project.name}`}</OverflowTooltip>
        </div>
    );
};

/******************************************* CameraSearchRow *******************************************/

// interface CameraSearchResultRowProps {
//     data: ICamera;
//     onResultClick: () => void;
// }
// const CameraSearchResultRow: FC<CameraSearchResultRowProps> = (props) => {
//     const { data: camera, onResultClick } = props;

//     const navigate = useNavigate();

//     const handleClick = () => {
//         navigate(`/projects/${(camera.project as IProject)._id}/cameras/${camera._id}`);

//         onResultClick();
//     };

//     return (
//         <div className="floating-list-item py-[10px]" onClick={handleClick}>
//             <div>
//                 <Img
//                     parentClassName="w-full h-9"
//                     className="w-full h-9 aspect-square rounded-md object-cover object-center"
//                     src={camera.latestImage?.urlThumb}
//                 />
//             </div>
//             <div className="flex items-center gap-1 overflow-hidden">
//                 <OverflowTooltip className="text-sm font-medium text-grayscale-600">{camera.name}</OverflowTooltip>
//                 <div className="text-xs font-medium text-grayscale-400">/</div>
//                 <OverflowTooltip className="text-xs font-medium text-grayscale-400">
//                     {(camera.project as IProject).name}
//                 </OverflowTooltip>
//             </div>
//         </div>
//     );
// };

/******************************************* CameraSearchRow *******************************************/

// interface CameraCctvSearchResultRowProps {
//     data: CameraCCTVModel;
//     onResultClick: () => void;
// }
// const CameraCctvSearchResultRow: FC<CameraCctvSearchResultRowProps> = (props) => {
//     const { data: camera, onResultClick } = props;

//     const navigate = useNavigate();

//     const handleClick = () => {
//         navigate(`/projects/${(camera.project as IProject)._id}/live-cameras/${camera._id}`);

//         onResultClick();
//     };

//     return (
//         <div className="floating-list-item py-[10px]" onClick={handleClick}>
//             <div>
//                 <Img
//                     parentClassName="w-full h-9"
//                     className="w-full h-9 aspect-square rounded-md object-cover object-center"
//                     src={camera.latestImage?.url}
//                 />
//             </div>
//             <div className="flex items-center gap-1 overflow-hidden">
//                 <OverflowTooltip className="text-sm font-medium text-grayscale-600">{camera.name}</OverflowTooltip>
//                 <div className="text-xs font-medium text-grayscale-400">/</div>
//                 <OverflowTooltip className="text-xs font-medium text-grayscale-400">
//                     {(camera.project as IProject).name}
//                 </OverflowTooltip>
//             </div>
//         </div>
//     );
// };

/******************************************* UserSearchResultRow *******************************************/

interface UserSearchResultRowProps {
    data: IUser;
    onResultClick: () => void;
}
const UserSearchResultRow: FC<UserSearchResultRowProps> = (props) => {
    const { data: user, onResultClick } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`clients/${(user.client as IClient)._id}/users/${user._id}/edit-user`);
        // navigate(`/users/${user._id}`);

        onResultClick();
    };

    return (
        <div className="floating-list-item py-[10px]" onClick={handleClick}>
            <div>
                <Avatar
                    src={user.dpUrl}
                    alt={user.name}
                    // backgroundColor={user.preset.color}
                />
            </div>

            <div className="overflow-hidden">
                <OverflowTooltip className="text-sm font-medium text-grayscale-600">{`${
                    (user.client as IClient).name
                } / ${user.name}`}</OverflowTooltip>

                {user.designation ? (
                    <OverflowTooltip className="text-xs font-medium text-grayscale-400">
                        {user.designation}
                    </OverflowTooltip>
                ) : (
                    <i>No designation</i>
                )}
            </div>
        </div>
    );
};
/******************************************* ClientResultRow *******************************************/
interface ClientSearchResultRowProps {
    data: IClient;
    onResultClick: () => void;
}
const CLientSearchResultRow: FC<ClientSearchResultRowProps> = (props) => {
    const { data: client, onResultClick } = props;

    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`clients/${client._id}/client-details`);
        onResultClick();
    };

    return (
        <div className="floating-list-item py-[10px]" onClick={handleClick}>
            <div>
                <Img
                    parentClassName="w-full h-9"
                    className="w-full h-9 aspect-square rounded-md object-cover object-center"
                    src={client.logoUrl}
                />
            </div>
            <OverflowTooltip className="text-sm font-medium text-grayscale-600">{client.name}</OverflowTooltip>
        </div>
    );
};

/******************************************* SearchResultRow *******************************************/

interface SearchResultRowProps {
    // data: IProject | ICamera | CameraCCTVModel | IUser;
    data: IProject | IUser | IClient;
    type: SearchTypeEnum;
    onResultClick: () => void;
}
const SearchResultRow: FC<SearchResultRowProps> = (props) => {
    const { data, type, onResultClick } = props;

    switch (type) {
        case SearchTypeEnum.PROJECT:
            return <ProjectSearchResultRow data={data as IProject} onResultClick={onResultClick} />;
        // case SearchTypeEnum.CAMERA:
        //     return <CameraSearchResultRow data={data as CameraModel} onResultClick={onResultClick} />;
        // case SearchTypeEnum.CAMERA_CCTV:
        //     return <CameraCctvSearchResultRow data={data as CameraCCTVModel} onResultClick={onResultClick} />;
        case SearchTypeEnum.USER:
            return <UserSearchResultRow data={data as IUser} onResultClick={onResultClick} />;
        case SearchTypeEnum.CLIENT:
            return <CLientSearchResultRow data={data as IClient} onResultClick={onResultClick} />;

        default:
            return null;
    }
};

export default SearchResultRow;
