import { useMutation } from '@tanstack/react-query';
// import {
//     CameraCCTVModel,
//     CameraModel,
//     ICameraCCTVModel,
//     ICameraModel,
//     IProjectModel,
//     IUserModel,
//     ProjectModel,
//     UserModel,
// } from 'api/models';
// import { getSearchResults } from 'api/services';
import { useCallback, useMemo, useRef, useState } from 'react';
import { SearchTypeEnum } from '../enums/searchbar.enums';
import { getSearchResults } from '@api/services/search.service';
import { IClient, IProject, IUser } from '@api/models';

const useSearchQuery = () => {
    const [projects, setProjects] = useState<IProject[]>([]);
    // const [cameras, setCameras] = useState<CameraModel[]>([]);
    // const [cameraCctvs, setCameraCctvs] = useState<CameraCCTVModel[]>([]);
    const [users, setUsers] = useState<IUser[]>([]);
    const [clients, setClients] = useState<IClient[]>([]);

    const ref = useRef(null);

    const searchProjectsMutation = useMutation((q: string) => getSearchResults<IProject>('projects', q));
    // const searchCamerasMutation = useMutation((q: string) => getSearchResults<ICameraModel>('cameras', q));
    // const searchCameraCctvsMutation = useMutation((q: string) => getSearchResults<ICameraCCTVModel>('camera-cctvs', q));
    const searchUsersMutation = useMutation((q: string) => getSearchResults<IUser>('users', q));
    const searchClientsMutation = useMutation((q: string) => getSearchResults<IClient>('clients', q));

    const fetch = useCallback(
        (q: string) => {
            ref.current = q;

            searchProjectsMutation.mutateAsync(q).then((result) => {
                if (result.q === ref.current)
                    setProjects(
                        result.data.map(
                            (row) => row
                            // ProjectModel.fromObject(row)
                        )
                    );
            });

            // searchCamerasMutation.mutateAsync(q).then((result) => {
            //     if (result.q === ref.current) setCameras(result.data.map((row) => CameraModel.fromObject(row)));
            // });

            // searchCameraCctvsMutation.mutateAsync(q).then((result) => {
            //     if (result.q === ref.current) setCameraCctvs(result.data.map((row) => CameraCCTVModel.fromObject(row)));
            // });

            searchUsersMutation.mutateAsync(q).then((result) => {
                if (result.q === ref.current)
                    setUsers(
                        result.data.map(
                            (row) => row
                            // UserModel.fromObject(row)
                        )
                    );
            });
            searchClientsMutation.mutateAsync(q).then((result) => {
                if (result.q === ref.current)
                    setClients(
                        result.data.map(
                            (row) => row
                            // UserModel.fromObject(row)
                        )
                    );
            });
        },
        [
            searchProjectsMutation,

            // searchCameraCctvsMutation, searchCamerasMutation,

            searchUsersMutation,
        ]
    );

    const resetResults = useCallback(() => {
        setProjects([]);
        // setCameras([]);
        // setCameraCctvs([]);
        setUsers([]);
    }, []);

    const groups = useMemo(() => {
        return [
            {
                type: SearchTypeEnum.PROJECT,
                label: 'Projects',
                isLoading: searchProjectsMutation.isLoading,
                data: projects,
            },
            // {
            //     type: SearchTypeEnum.CAMERA,
            //     label: 'TimeLapse Cameras',
            //     isLoading: searchCamerasMutation.isLoading,
            //     data: cameras,
            // },
            // {
            //     type: SearchTypeEnum.CAMERA_CCTV,
            //     label: 'Live Cameras',
            //     isLoading: searchCameraCctvsMutation.isLoading,
            //     data: cameraCctvs,
            // },
            {
                type: SearchTypeEnum.USER,
                label: 'Users',
                isLoading: searchUsersMutation.isLoading,
                data: users,
            },
            {
                type: SearchTypeEnum.CLIENT,
                label: 'Clients',
                isLoading: searchClientsMutation.isLoading,
                data: clients,
            },
        ];
    }, [
        projects,
        // cameras,
        // cameraCctvs,
        users,
        clients,
        searchProjectsMutation.isLoading,
        // searchCamerasMutation.isLoading,
        // searchCameraCctvsMutation.isLoading,
        searchUsersMutation.isLoading,
        searchClientsMutation.isLoading,
    ]);

    return {
        fetch,
        resetResults,
        groups,
        isEmpty: groups.every((group) => group.data.length === 0),
        isLoading: groups.some((group) => group.isLoading),
    };
};

export default useSearchQuery;
