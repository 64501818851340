import http from 'httpConfig/http';
import { getInternalApplicationUrlById } from './internal-applications.service';
import { getResellerIdFromRedux } from '@utils/common';

export const getSearchResults = async <T = unknown>(searchParam: string, q: string) => {
    const { data } = await http.get(
        `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/search/${searchParam}`,
        {
            params: {
                q,
            },
        }
    );

    return { data, q } as { data: T[]; q: string };
};
