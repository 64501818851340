import { IUser } from '@api/models/user.model';
import { CrossIcon } from '@icon/index';
import { DatePicker } from '@library/datepicker';
import { FormHelperText, Input, Label } from '@library/forms';
import { CircularProgress } from '@library/loaders/components';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import CustomSwitch from 'modules/shared/components/FormFields/CustomSwitch';
import RequiredMark from 'modules/shared/components/FormFields/RequiredMark';
import { useState } from 'react';
import { ColorResult } from 'react-color';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { roleOption, themeOption, timezoneOption } from '../../helpers/user.utils';
import useUniqueMutation from '../../hooks/useUniqueMutation';

import { Button, Chip, Drawer } from '@hyperflake/react-ui-library';
import ColorPicker from 'modules/shared/components/ColorPicker/ColorPicker';
import { FormikInput, FormikSelect } from 'modules/shared/features/formik';
import { UploadIcon } from 'modules/shared/icons';
import useUserAddForm from 'modules/users/hooks/useUserAddForm';
import PhoneDetails from '../PhoneDetails/PhoneDetails';

interface IUserAddDrawerFormProps {
    open: boolean;
    onSubmit: (id: string) => void;
    onClose: () => void;
    onAddUser: (user: IUser) => void;
}

const UserAddDrawerForm = (props: IUserAddDrawerFormProps) => {
    const { open, onClose, onSubmit, onAddUser } = props;
    const { clientId } = useParams();
    const [tagHolder, setTagHolder] = useState('');
    const { uniqueEmailMutation, uniqueUsernameMutation } = useUniqueMutation();
    const addUserForm = useUserAddForm(clientId);

    const handleSubmit = async (values: any) => {
        try {
            const data = await addUserForm.submit(values);
            toast.success('User Added Successfully');

            onAddUser(data);
            onSubmit(data._id);
        } catch (e: any) {
            console.log(e);
            toast.error('Oops something went wrong.');
        }
    };

    // const { hasAdminPermission } = useAuthPermission();

    // if (!hasAdminPermission) return <AdminPermission formTitle="User" />;

    return (
        <Drawer open={open} onClose={onClose} title="Add User">
            <div>
                <div>
                    <Formik
                        initialValues={addUserForm.initialValues}
                        //enableReinitialize
                        validationSchema={addUserForm.schema}
                        validateOnChange={false}
                        onSubmit={handleSubmit}
                    >
                        {({ setFieldValue, values, isValidating }) => (
                            <Form>
                                <div className="flex flex-col items-start">
                                    <Label>User DP</Label>

                                    <div className="flex items-center gap-4 mt-2">
                                        <div className="relative w-20 h-20 bg-grayscale-200 rounded-full">
                                            {values.file && (
                                                <>
                                                    <img
                                                        className="w-full h-full rounded-full "
                                                        src={URL.createObjectURL(values.file)}
                                                        alt=""
                                                    />
                                                    <div
                                                        className="absolute top-0 left-0 w-full h-full rounded-full flex items-center justify-center text-xs font-semibold text-white bg-black/40 cursor-pointer select-none transition-opacity duration-300 opacity-0 hover:opacity-100"
                                                        onClick={() => setFieldValue('file', null)}
                                                    >
                                                        Remove
                                                    </div>
                                                </>
                                            )}
                                        </div>

                                        <div className="relative">
                                            <Button variant="shaded" size="xs">
                                                <UploadIcon className="cursor-pointer" /> Upload Image
                                                <input
                                                    type="file"
                                                    className="absolute top-0 left-0 bottom-0 right-0 w-full h-full opacity-0"
                                                    onChange={(event) => {
                                                        const files = event.target.files;

                                                        if (files && files.length > 0) {
                                                            setFieldValue('file', files[0]);
                                                        }
                                                    }}
                                                />
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-4 ">
                                    {/* name */}
                                    <div className="">
                                        <Label>
                                            Name <RequiredMark />
                                        </Label>
                                        <FormikInput type="text" name="name" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="name" />
                                        </FormHelperText>
                                    </div>
                                    {/* username */}
                                    <div className="">
                                        <Label>
                                            Username <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2 items-center">
                                            <FormikInput type="text" name="username" />
                                            {uniqueUsernameMutation.isLoading && <CircularProgress size={14} />}
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="username" />
                                        </FormHelperText>
                                    </div>
                                    {/* email */}
                                    <div className="">
                                        <Label>
                                            Email <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2 items-center">
                                            <FormikInput type="email" name="email" />
                                            {uniqueEmailMutation.isLoading && <CircularProgress size={14} />}
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="email" />
                                        </FormHelperText>
                                    </div>
                                    {/* timezone */}
                                    <div>
                                        <Label>
                                            Role <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect
                                                name="role"
                                                options={roleOption}
                                                // disabled={formik.values.pointedTo !== ''}
                                            />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="role" />
                                        </FormHelperText>
                                    </div>
                                    {/* designation */}
                                    <div className="">
                                        <Label>Designation</Label>

                                        <FormikInput type="text" name="designation" />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="designation" />
                                        </FormHelperText>
                                    </div>
                                    {/* dob */}
                                    <div className="">
                                        <Label>Date of birth</Label>
                                        <DatePicker
                                            className="flex items-center w-full font-medium bg-transparent border border-grayscale-300 placeholder:text-grayscale-400 focus:border-primary focus:outline focus:outline-2 focus:outline-primary/10 text-sm px-4 h-10 rounded-md"
                                            onChange={(val) => setFieldValue('dob', val)}
                                            value={values.dob}
                                            maxDate={new Date()}
                                        />
                                        {/* <FormikDatePicker name="dob" /> */}
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="dob" />
                                        </FormHelperText>
                                    </div>
                                    {/* mobile number */}
                                    <div className="">
                                        <Label>Phone Number</Label>
                                        <PhoneDetails />
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="phone.number" />
                                        </FormHelperText>
                                    </div>
                                    {/* timezone */}
                                    <div>
                                        <Label>
                                            Timezone <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect
                                                name="preferences.timezone"
                                                options={timezoneOption}
                                                showSearch
                                                // disabled={formik.values.pointedTo !== ''}
                                            />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="preferences.timezone" />
                                        </FormHelperText>
                                    </div>
                                    {/* primary color */}
                                    <div className="">
                                        <Label>Primary Color</Label>
                                        <ColorPicker
                                            value={values.preferences.primaryColor}
                                            setColor={(event: ColorResult) => {
                                                setFieldValue('preferences.primaryColor', event.hex.toUpperCase());
                                            }}
                                        />
                                    </div>
                                    {/* theme */}
                                    <div className="mt-4">
                                        <Label>
                                            Theme <RequiredMark />
                                        </Label>
                                        <div className="flex gap-2">
                                            <FormikSelect
                                                name="preferences.theme"
                                                options={themeOption}
                                                // disabled={formik.values.pointedTo !== ''}
                                            />
                                        </div>
                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="preferences.theme" />
                                        </FormHelperText>
                                    </div>
                                    <div className="grid grid-cols-2">
                                        <div className="">
                                            <CustomSwitch
                                                name="preferences.isEmailNotificationEnabled"
                                                label="Email Notification"
                                            />
                                            <FormHelperText variant={'error'} />
                                        </div>
                                        <div className="">
                                            <CustomSwitch name="preferences.show4k" label="Show 4K" />
                                            <FormHelperText variant={'error'} />
                                        </div>
                                        <div className="">
                                            <CustomSwitch name="preferences.showZoomed" label="Show Zoomed" />
                                            <FormHelperText variant={'error'} />
                                        </div>
                                        <div className="">
                                            <CustomSwitch name="preferences.showTimestamp" label="Show Timestamp" />
                                            <FormHelperText variant={'error'} />
                                        </div>
                                    </div>

                                    <div>
                                        <Label>Tags</Label>
                                        <FieldArray
                                            name="tags"
                                            render={(arrayHelpers) => {
                                                return (
                                                    <div className="">
                                                        <div className="">
                                                            <Input
                                                                className="form-control"
                                                                type="text"
                                                                placeholder="Add tag"
                                                                value={tagHolder}
                                                                onKeyDown={(
                                                                    event: React.KeyboardEvent<HTMLElement>
                                                                ) => {
                                                                    if (event.key === 'Enter') {
                                                                        const trimmedValue = tagHolder.trim();
                                                                        if (trimmedValue !== '') {
                                                                            arrayHelpers.push(trimmedValue);
                                                                            setTagHolder(() => '');
                                                                            event.preventDefault();
                                                                        }
                                                                    }
                                                                }}
                                                                onInput={(event: any) => {
                                                                    setTagHolder(event.target.value);
                                                                }}
                                                                onBlur={() => {
                                                                    setTagHolder('');
                                                                }}
                                                            />
                                                        </div>
                                                        <div className="flex flex-wrap gap-2 mt-4  ">
                                                            {values.tags.map((_: any, index: number) => (
                                                                <Chip
                                                                    key={index}
                                                                    label={values.tags[index]}
                                                                    variant={'default'}
                                                                    onClick={() => {
                                                                        arrayHelpers.remove(index);
                                                                    }}
                                                                    className="cursor-pointer"
                                                                    icon={<CrossIcon width={8} height={8} />}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                );
                                            }}
                                        />

                                        <FormHelperText variant={'error'}>
                                            <ErrorMessage name="timezone" />
                                        </FormHelperText>
                                    </div>
                                </div>

                                <Button
                                    disabled={addUserForm.isSubmitting || isValidating}
                                    type="submit"
                                    size={'lg'}
                                    className="mt-8 w-28"
                                >
                                    {addUserForm.isSubmitting ? (
                                        <CircularProgress size={20} color={'inherit'} />
                                    ) : isValidating ? (
                                        'Validating'
                                    ) : (
                                        'Submit'
                                    )}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        </Drawer>
    );
};

export default UserAddDrawerForm;
