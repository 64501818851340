import { ICamera, ICameraCCTV } from '@api/models';
import { updateCctvCamera } from '@api/services/cctv-camera.service';
import { useMutation } from '@tanstack/react-query';
import moment from 'moment';
import * as Yup from 'yup';

interface EditCctvCameraFormOptions {
    cctvCamera: ICameraCCTV;
    clientId: string;
    projectId: string;
    // cameraId: string;
}
const useCctvCameraUpdateForm = (options: EditCctvCameraFormOptions) => {
    const { cctvCamera, clientId, projectId } = options;

    const updateCameraMutation = useMutation((payload: ICamera) =>
        updateCctvCamera(clientId, projectId, cctvCamera._id, payload)
    );

    const schema = Yup.object().shape({
        name: Yup.string().trim().required('This field is required'),
        installationDate: Yup.string().trim().required('This field is required'),
        streamingUrl: Yup.string().trim().url('Enter a valid Url').required('This field is required'),
        qLiveUnit: Yup.string().trim().required('This field is required'),
        location: Yup.object().shape({
            latitude: Yup.string().trim(),
            longitude: Yup.string().trim(),
        }),
        storage: Yup.string().required('This field is required'),
    });
    const initialValues = {
        name: cctvCamera?.name,
        installationDate: moment(cctvCamera?.installationDate, 'YYYYMMDD'),
        streamingUrl: cctvCamera?.streamingUrl,
        location: {
            latitude: cctvCamera?.location.latitude,
            longitude: cctvCamera?.location.longitude,
        },
        status: cctvCamera?.status,
        storage: cctvCamera?.storageName,
        qLiveUnit: cctvCamera?.qLiveUnit,
    };

    const submit = async (values: ICamera) => {
        const data = await updateCameraMutation.mutateAsync(values);
        return data;
    };
    return {
        schema,
        initialValues,
        submit,
        isSubmitting: updateCameraMutation.isLoading,
    };
};
export default useCctvCameraUpdateForm;
