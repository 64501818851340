const Icon = (props: any) => {
    return (
        <svg width={14} height={12} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M2.545 11.25V7.168M2.545 4.833V.75M7.001 11.25V6M7.001 3.667V.75M11.455 11.25V8.333M11.455 6V.75M.875 7.167h3.341M5.33 3.667h3.34M9.784 8.333h3.341"
                stroke="currentColor"
                strokeWidth={1.225}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
