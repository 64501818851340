import { ICamera, IProject } from '@api/models';
import { getProjectOnSearch, moveProjectOfCamera } from '@api/services/project.service';
import AutoComplete from '@components/AutoComplete/AutoComplete';
import { Button, Input, List, ListItem } from '@hyperflake/react-ui-library';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

interface ChangeProjectFormProps {
    camera: ICamera;
}

const ChangeProjectForm = (props: ChangeProjectFormProps) => {
    const { camera } = props;
    const { clientId, projectId } = useParams();
    // const {camera,isLoading} = useCameraData(clientId, projectId, cameraId);
    const [fieldValue, setFieldValue] = useState('');
    const [projectList, setProjectList] = useState<IProject[]>([]);
    const [selectedProject, setSelectedProject] = useState<IProject>(null);
    const ProjectListQuery = useMutation((searchParam: string) => getProjectOnSearch(clientId, projectId, searchParam));
    const projectMoveMutation = useMutation((newProjectId: string) =>
        moveProjectOfCamera(clientId, projectId, camera._id, newProjectId)
    );
    const navigate = useNavigate();
    const filterProject = (newProjects: IProject[]) => {
        const filteredProjects = newProjects.filter((project) => project._id !== (camera.project as IProject)._id);

        setProjectList(filteredProjects);
    };

    const getProjectList = async (q: string) => {
        try {
            const data = await ProjectListQuery.mutateAsync(q);
            filterProject(data);
        } catch (err: any) {
            setFieldValue('');
            setProjectList([]);
            console.error(err);
        }
    };

    const debouncedGetExternalProject = (value: string) => {
        if (value.length > 1) {
            getProjectList(value);
        }
    };

    useEffect(() => {
        const timeOutId = setTimeout(() => debouncedGetExternalProject(fieldValue), 500);
        return () => clearTimeout(timeOutId);
    }, [fieldValue]);

    const handleUpdate = async () => {
        try {
            const data = await projectMoveMutation.mutateAsync(selectedProject._id);
            toast(`Camera Moved To ${selectedProject.name} successfully `);
            navigate(`/clients/${clientId}/projects/${selectedProject?._id}/cameras`);
        } catch (err: any) {
            console.error(err);
        }
    };

    return (
        <>
            <div>
                <div className="mt-4 text-base font-semibold">
                    Move {camera.name}'s From : {(camera.project as IProject).name}
                </div>
            </div>
            <div className="mt-4">Move To :{selectedProject?.name} </div>
            <div className="relative flex items-center w-full mt-4">
                <AutoComplete
                    renderInput={(inputProps) => <Input {...inputProps} placeholder="Search Projects" />}
                    value={fieldValue}
                    onChange={(value) => {
                        setFieldValue(value);

                        // debouncedGetExternalProject(value);
                    }}
                    onOptionSelect={(option) => {
                        setSelectedProject(option);
                        setFieldValue('');
                        setProjectList([]);
                    }}
                    options={projectList}
                    renderOption={(option: any) => (
                        <div className="relative flex items-center w-full border-black max-h-10  bg-white ">
                            <List divider className="overflow-hidden ">
                                <ListItem className="w-full ">
                                    {option.name}-{option._id}
                                </ListItem>
                            </List>
                        </div>
                    )}
                    // isLoading={externalProjectQuery.isLoading}
                    searchableFields={['name']}
                />
            </div>
            {/* <div>
                <div>Move To :{selectedProject?.name} </div>
            </div> */}
            <Button
                type="submit"
                // disabled={updateExternalProjectMutation.isLoading}
                size={'lg'}
                className="mt-8 w-28"
                onClick={handleUpdate}
            >
                Submit
                {/* {updateExternalProjectMutation.isLoading ? <CircularProgress size={20} color={'inherit'} /> : 'Save'} */}
            </Button>
        </>
    );
};

export default ChangeProjectForm;
