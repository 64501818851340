const Icon = (props: any) => {
    return (
        <svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.294 6.647A5.647 5.647 0 111 6.647a5.647 5.647 0 0111.294 0z"
                stroke="currentColor"
                strokeWidth={props.strokeWidth || 1.5}
            />
            <path
                d="M10.883 10.883L13 13"
                stroke="currentColor"
                strokeWidth={props.strokeWidth || 1.5}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default Icon;
