import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Drawer, LinearProgress, NavTab } from '@hyperflake/react-ui-library';
import { ProjectEditDrawerFormProps, TabEnum } from 'modules/projects/helpers/project.types';
import useProjectData from 'modules/projects/hooks/useProjectData';
import useProjectUpdateForm from 'modules/projects/hooks/useProjectUpdateForm';
import ProjectEditForm from '../ProjectEditForm/ProjectEditForm';
import ProjectRendersDrawer from '../ProjectRendersDrawer/ProjectRendersDrawer';

// type IProjectLean = Pick<IProject, '_id' | 'status' | 'name' | 'hasCameras'>;

const ProjectEditDrawerForm = (props: ProjectEditDrawerFormProps) => {
    const { open, onClose, projectId, updateProject } = props;
    const [activeTab, setActiveTab] = useState(TabEnum.PROJECT_EDIT);

    const { clientId } = useParams();
    const { project, isLoading } = useProjectData(clientId, projectId);
    const updateProjectFrom = useProjectUpdateForm({ project, clientId });

    return (
        <Drawer open={open} onClose={onClose} title="Edit Project">
            <NavTab
                items={[
                    {
                        content: 'Project Edit',
                        isActive: TabEnum.PROJECT_EDIT === activeTab,
                        onClick: () => setActiveTab(TabEnum.PROJECT_EDIT),
                    },
                    {
                        content: 'Project Render',
                        isActive: TabEnum.PROJECT_RENDER === activeTab,
                        onClick: () => setActiveTab(TabEnum.PROJECT_RENDER),
                    },
                ]}
            />
            {activeTab === TabEnum.PROJECT_EDIT && (
                <div>
                    {isLoading || updateProjectFrom.projectAsParentIsloading ? (
                        <LinearProgress color="primary" />
                    ) : (
                        <ProjectEditForm updateProject={updateProject} projectId={projectId} onClose={onClose} />
                    )}
                </div>
            )}
            {activeTab === TabEnum.PROJECT_RENDER && <ProjectRendersDrawer projectId={projectId} />}
        </Drawer>
    );
};

export default ProjectEditDrawerForm;
