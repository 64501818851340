import { useEffect, useRef, useState } from 'react';

const createBimCssLink = () => {
    const link = document.createElement('link');

    link.rel = 'stylesheet';
    link.href = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/style.min.css';
    link.type = 'text/css';

    return link;
};

const createBimScriptLink = () => {
    const script = document.createElement('script');

    script.id = 'autodeskScript';
    script.src = 'https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js';
    script.async = true;

    return script;
};

const useBimScriptLoader = () => {
    const isMounted = useRef(false);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<any>(null);

    useEffect(() => {
        if (isMounted.current) return;

        const link = createBimCssLink();
        const script = createBimScriptLink();

        script.onload = async () => {
            setIsLoading(false);
        };

        script.onerror = async (err) => {
            setError(err);
        };

        document.head.appendChild(link);
        document.body.appendChild(script);

        isMounted.current = true;

        return () => {
            document.body.removeChild(script);
            document.head.removeChild(link);

            isMounted.current = false;
        };
    }, []);

    return {
        isLoading,
        error,
    };
};

export default useBimScriptLoader;
