import Slider from 'rc-slider';
import { Tooltip } from '@library/tooltip';
import { IconButton } from '@library/buttons';
import { MinusIcon, PlusIcon } from '@icon/index';
import { AnimatePresence, motion } from 'framer-motion';
import {
    ComponentPropsWithRef,
    FC,
    ForwardedRef,
    ReactNode,
    forwardRef,
    useCallback,
    useImperativeHandle,
} from 'react';
import { IZoomableRef, useZoomable } from '@components/zoomable';

const controlsAnimation = {
    variants: {
        hidden: {
            opacity: 0,
        },
        visible: {
            opacity: 1,
        },
    },
    transition: { duration: 0.35 },
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
};
// interface EventBlockerProps extends ComponentPropsWithRef<"div"> {
//     stopPropagation?: boolean;
//     preventDefault?: boolean;
//   }

//   const EventBlocker = forwardRef(
//     (props: EventBlockerProps, ref: ForwardedRef<HTMLDivElement>) => {
//       const { children, stopPropagation, preventDefault, onClick, ...rest } =
//         props;

interface ZoomViewerProps extends ComponentPropsWithRef<'div'> {
    children: ReactNode;
    contentWidth: number;
    contentHeight: number;
    containerWidth?: number;
    containerHeight?: number;
    showControls?: boolean;
    zoomedView?: boolean;
}

const ZoomViewer = forwardRef((props: ZoomViewerProps, ref: ForwardedRef<IZoomableRef>) => {
    const { children, contentWidth, contentHeight, containerWidth, containerHeight, showControls, zoomedView } = props;

    const { getContainerProps, getContentProps, state, zoomIn, zoomOut, zoomTo } = useZoomable({
        bounds: { width: contentWidth, height: contentHeight },
        zoomedToContent: zoomedView,
    });

    useImperativeHandle(ref, () => {
        return {
            zoomIn,
            zoomOut,
            zoomTo,
        };
    });

    const handleSliderValueChange = useCallback(
        (val: number) => {
            zoomTo(val);
        },
        [zoomTo]
    );

    function getCssVariableValue(variableName: string) {
        const value = getComputedStyle(document.documentElement).getPropertyValue(variableName).trim();

        return parseFloat(value);
    }

    return (
        <div className="relative">
            <div
                {...getContainerProps({
                    style: {
                        width: containerWidth,
                        height: containerHeight - getCssVariableValue('--navbar-height'),
                        cursor: 'grab',
                    },
                })}
            >
                <div
                    {...getContentProps({
                        style: { width: contentWidth, height: contentHeight },
                    })}
                >
                    {children}
                </div>
            </div>

            <AnimatePresence initial={false} mode="wait">
                {showControls && (
                    <motion.div {...controlsAnimation} className="absolute bottom-6 left-[50%] translate-x-[-50%]">
                        {/* <div className='flex items-center gap-6'>
                            <Tooltip placement='top' content='Zoom out' onClick={() => zoomOut()}>
                                <IconButton
                                    className='bg-white text-grayscale-600'
                                    // className='opacity-70'
                                    color='secondary'
                                >
                                    <MinusIcon width='14' height='8' />
                                </IconButton>
                            </Tooltip>

                            <div className='w-[220px]'>
                                <Slider
                                    styles={{
                                        handle: {
                                            borderColor: 'rgba(255,255,255,0.9)',
                                            backgroundColor: 'rgba(255,255,255,1)',
                                            boxShadow: 'none',
                                            height: 20,
                                            width: 20,
                                            marginTop: -7,
                                            opacity: 1,
                                        },
                                        rail: { backgroundColor: 'rgba(255,255,255,0.3)', height: 6 },
                                        track: { backgroundColor: 'rgba(255,255,255,0.6)', height: 6 },
                                    }}
                                    min={1}
                                    max={14}
                                    step={0.1}
                                    value={state.k}
                                    onChange={(val) => handleSliderValueChange(val as number)}
                                />
                            </div>

                            <Tooltip placement='top' content='Zoom in'>
                                <IconButton
                                    className='bg-white text-grayscale-600'
                                    // className='opacity-70'
                                    color='secondary'
                                    onClick={() => zoomIn()}
                                >
                                    <PlusIcon width='14' height='14' />
                                </IconButton>
                            </Tooltip>
                        </div> */}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
});

ZoomViewer.defaultProps = {
    showControls: false,
    zoomedView: false,
};

export default ZoomViewer;
